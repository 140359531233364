import { styled } from "@mui/material/styles";

const classes = {
    logoContainer: `logoContainer`,
    logo: `logo`,
    container: `container`,
    title: `title`,
    searchForm: `searchForm`,
    searchTxt: `searchTxt`,
    alertContainer: `alertContainer`,
    alert: `alert`,
    restaurantItem: `restaurantItem`,
    spinnerContainer: `spinnerContainer`
};

const Root = styled("div")(({ theme }) => ({
    [`& .${classes.logoContainer}`]: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    [`& .${classes.logo}`]: { height: "70px", margin: "20px 0 20px 20px" },
    [`& .${classes.container}`]: {
        justifyContent: "center"
    },
    [`& .${classes.title}`]: {
        fontWeight: 200,
        fontSize: "20px",
        textAlign: "center",
        marginBottom: "40px",
        fontFamily: "Poppins"
    },
    [`& .${classes.searchForm}`]: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "0 20px 0 20px"
    },
    [`& .${classes.searchTxt}`]: {
        minWidth: "300px",
        maxWidth: "500px"
    },
    [`& .${classes.alertContainer}`]: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "0 25px 0 25px"
    },
    [`& .${classes.alert}`]: {
        width: "100%"
    },
    [`& .${classes.restaurantItem}`]: {
        fontSize: "1.2rem",
        fontWeight: 300,
        textAlign: "center",
        "&:hover": {
            cursor: "pointer"
        }
    },
    [`& .${classes.spinnerContainer}`]: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    }
}));
export { Root, classes };
