import React from "react";
import WebCollection1 from "../WebTemplates/Template1/Collection";
import WebTemplate1 from "../WebTemplates/Template1/Menu";

export interface IWebPages {
    menuPage: () => React.ReactElement;
    collectionPage: () => React.ReactElement;
}

interface IWebTemplates {
    "1": IWebPages;
}

interface IWebTemplateProps {}

const templates: IWebTemplates = {
    "1": {
        menuPage: () => <WebTemplate1></WebTemplate1>,
        collectionPage: () => <WebCollection1></WebCollection1>
    }
};

export const getWebTemplatePages = (id: any, props: IWebTemplateProps) => {
    const _id = id as keyof IWebTemplates;

    return id in templates ? templates[_id] : templates["1"];
};
