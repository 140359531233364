import { styled } from "@mui/material/styles";
import { IPresentTemplate } from "../../../const";

type Props = {
    template?: IPresentTemplate;
};

const classes = {
    gridContainer: `gridContainer`,
    landing_message: `landing_message`,
    landing_button: `landing_button`,
    landing_button_2: `landing_button_2`,
    landing_button_3: `landing_button_3`,
    collectionContainer: `collectionContainer`,
    secondaryMessage: `secondaryMessage`,
    description: `description`,
    contentWrapper: `contentWrapper`,
    imageLoading: `imageLoading`,
    imageLoaded: `imageLoaded`
};

const buttonStyles = (template: any, key: string) => {
    const fallbackKey = "collection_name";
    const defaultColor = template?.fonts?.[fallbackKey]?.color || "#000000";

    return {
        fontFamily:
            template?.fonts?.[key]?.family ||
            template?.fonts?.[fallbackKey]?.family ||
            "Inter",
        fontSize:
            template?.fonts?.[key]?.size ||
            template?.fonts?.[fallbackKey]?.size ||
            "1.2rem",
        fontWeight:
            template?.fonts?.[key]?.weight ||
            template?.fonts?.[fallbackKey]?.weight ||
            "500",
        lineHeight:
            template?.fonts?.[key]?.line_height ||
            template?.fonts?.[fallbackKey]?.line_height ||
            "3rem",
        color: template?.fonts?.[key]?.color || defaultColor,
        fontStyle:
            template?.fonts?.[key]?.style ||
            template?.fonts?.[fallbackKey]?.style ||
            "normal",
        backgroundColor:
            template?.fonts?.[key]?.background ||
            template?.colors?.background_1 ||
            "#fff",
        textAlign: "center",
        borderRadius: template?.fonts?.[key]?.border_radius || "15px",
        padding: template?.fonts?.[key]?.padding || "10px 0 10px 0",
        gap: "10px",
        decoration: "none",
        borderWidth: template?.fonts?.[key]?.border_width || "1px",
        borderColor: template?.fonts?.[key]?.border_color || defaultColor,
        borderStyle: "solid",
        boxShadow:
            template?.fonts?.[key]?.button_box_shadow ||
            `1px 2px 1px ${defaultColor}`,
        width: template?.fonts?.[key]?.button_width || "100%",
        cursor: "pointer",
        transition: ".3s",
        margin: "0 0 25px 0",
        "&:hover": {
            transform: "Scale(1.02)"
        },
        "&:active": {
            transform: "Scale(1)"
        },
        "&.top": {
            order: 1
        },
        "&.bottom": {
            order: 3
        },
        "& ~ .collectionContainer": {
            marginTop: "0"
        }
    };
};

const Root = styled("div")<Props>(({ template }) => ({
    [`& .${classes.gridContainer}`]: {},
    [`& .${classes.landing_message}`]: {
        fontFamily: template?.fonts?.restaurant_message?.family || "Montserrat",
        fontSize: template?.fonts?.restaurant_message?.size || "1.1rem",
        fontWeight: template?.fonts?.restaurant_message?.weight || 800,
        lineHeight:
            template?.fonts?.restaurant_message?.line_height || "2.5rem",
        color: template?.fonts?.restaurant_message?.color || "black",
        fontStyle: template?.fonts?.restaurant_message?.style || "normal",
        textAlign: "center"
    },
    "& .landing_button": buttonStyles(template, "restaurant_button"),
    "& .landing_button_2": buttonStyles(template, "restaurant_button_2"),
    "& .landing_button_3": buttonStyles(template, "restaurant_button_3"),
    [`& .${classes.collectionContainer}`]: {
        border: `1px solid ${
            template?.fonts?.collection_name?.color || "#000000"
        }`,
        boxShadow: `1px 2px 1px ${
            template?.fonts?.collection_name?.color || "#000000"
        }`,
        borderRadius: "15px",
        padding: "10px 0 10px 0",
        margin: "0 0 25px 0",
        cursor: "pointer",
        transition: ".3s",
        backgroundColor: template?.colors?.background_1 || "#fff",
        width: "100%",
        "&:hover": {
            transform: "Scale(1.02)"
        },
        "& + .collectionContainer": {
            marginTop: 0
        }
    },
    [`& .${classes.secondaryMessage}`]: {
        textAlign: "center",
        cursor: "pointer",
        margin: "0 0 0 0",
        fontFamily: template?.fonts?.collection_name?.family || "Inter",
        fontSize: template?.fonts?.collection_name?.size || "1.2rem",
        fontWeight: template?.fonts?.collection_name?.weight || "500",
        lineHeight: template?.fonts?.collection_name?.line_height || "3rem",
        color: template?.fonts?.collection_name?.color || "#000000",
        fontStyle: template?.fonts?.collection_name?.style || "normal"
    },
    [`& .${classes.description}`]: {
        textAlign: "center",
        margin: "0 0 0 0",
        fontFamily: template?.fonts?.collection_description?.family || "Inter",
        fontSize: template?.fonts?.collection_description?.size || ".9rem",
        fontWeight: template?.fonts?.collection_description?.weight || "300",
        lineHeight:
            template?.fonts?.collection_description?.line_height || "1.7rem",
        color: template?.fonts?.collection_description?.color || "#000000",
        fontStyle: template?.fonts?.collection_description?.style || "normal"
    },
    [`&.${classes.contentWrapper}`]: {
        opacity: 0,
        transition: "opacity 0.3s ease-in-out"
    },
    [`&.${classes.imageLoading}`]: {
        opacity: 0,
        transition: "opacity 0.3s ease-in-out"
    },
    [`&.${classes.imageLoaded}`]: {
        opacity: 1
    }
}));

export { Root, classes };
