import { useGlobalContext } from "../../../../context/Global";
import { Root, classes } from "./styles";

export default function NoSearchResults(props: {
    isFavoritePage: boolean;
    filteredItems?: any;
}) {
    const { isFavoritePage, filteredItems } = props;

    const { setSearch, fullMenu } = useGlobalContext();
    const colors = fullMenu?.presenter_template?.colors;
    const fonts = fullMenu?.presenter_template.fonts;

    return (
        <>
            <Root
                className={classes.container}
                sx={{
                    background: `${colors ? colors.primary : "#ffffff"}`,

                    color: fullMenu?.presenter_template?.fonts?.section_links
                        ?.color,
                    m: 0,

                    cursor: "pointer",
                    textDecorationLine: "underline",
                    fontFamily:
                        fullMenu?.presenter_template.fonts?.section_links
                            ?.family,
                    fontWeight:
                        fullMenu?.presenter_template.fonts?.section_links
                            ?.weight,
                    fontSize:
                        fullMenu?.presenter_template.fonts?.section_links?.size,
                    fontStyle:
                        fullMenu?.presenter_template.fonts?.section_links
                            ?.style,
                    lineHeight:
                        fullMenu?.presenter_template.fonts?.section_links
                            ?.line_height
                }}
            >
                {filteredItems && (
                    <>
                        <h5
                            className={classes.title}
                            style={{ color: "white" }}
                        >
                            {isFavoritePage
                                ? "You don’t have any favorites yet"
                                : "There are no results that match your search."}
                        </h5>
                        <p
                            className={classes.content}
                            style={{ color: "white" }}
                        >
                            {isFavoritePage
                                ? "Mark some items as “favorite” and then come back here to see them."
                                : "Please try adjusting your search keywords."}
                        </p>
                    </>
                )}
                <p
                    style={{
                        display: isFavoritePage ? "none" : "block",
                        fontFamily: fonts?.section_links?.family || "Inter",
                        fontSize: fonts?.section_links?.size,
                        fontWeight: fonts?.section_links?.weight,
                        fontStyle: fonts?.section_links?.style,
                        lineHeight: fonts?.section_links?.line_height,
                        color: fonts?.section_links?.color,
                        textDecorationLine: "underline"
                    }}
                    className={classes.clear}
                    onClick={() => setSearch && setSearch("")}
                >
                    Clear Search
                </p>
            </Root>
        </>
    );
}
