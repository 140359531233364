import { ToggleOff, ToggleOn } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { useGlobalContext } from "../../../../context/Global";
import { FormattedMessage, useIntl } from "react-intl";

export default function ShowNotationToggle(props: {
    showNotations: boolean;
    handleToggle: any;
}) {
    const { fullMenu, menu } = useGlobalContext();
    const intl = useIntl();
    // In order to show the notations toggle, the menu shoud have the flag show_notations setted as true.
    return menu?.menu_id?.show_notations ? (
        <div
            style={{
                display: "flex",
                justifyContent: "flex-start",
                flexWrap: "nowrap",
                alignItems: "center",
                padding: "15px 22px 15px 22px",
                fontFamily:
                    fullMenu?.presenter_template.fonts?.notations_slider
                        ?.family || "Inter",
                fontSize:
                    fullMenu?.presenter_template.fonts?.notations_slider
                        ?.size || "1rem",
                fontWeight:
                    fullMenu?.presenter_template.fonts?.notations_slider
                        ?.weight || "400",
                color:
                    fullMenu?.presenter_template.fonts?.notations_slider
                        ?.color || "#000000"
            }}
        >
            <IconButton
                disableRipple={true}
                style={{
                    marginRight: "20px",
                    color:
                        fullMenu?.presenter_template.colors?.primary ||
                        "#CC5803"
                }}
                onClick={props.handleToggle}
                aria-label={intl.formatMessage({ id: "items.dr&a" })}
            >
                {props.showNotations ? (
                    <ToggleOn style={{ transform: "Scale(2)" }} />
                ) : (
                    <ToggleOff
                        style={{
                            color:
                                fullMenu?.presenter_template.colors
                                    ?.background_2 || "#9E9E9E",
                            transform: "Scale(2.1)"
                        }}
                    />
                )}
            </IconButton>
            <FormattedMessage id="items.dr&a" />
        </div>
    ) : (
        <div style={{ height: "30px" }}></div>
    );
}
