import { CART_LOCAL_STORAGE } from "../const/favorites";
import { isLocalStorageAvailable } from "./localStorage";

interface ICART {
    cartItem: Array<object>;
}

export class CartItem {
    restaurantIdentifier: string;
    constructor(restaurantIdentifier: string) {
        this.restaurantIdentifier = restaurantIdentifier;
    }

    private getName() {
        return `${CART_LOCAL_STORAGE}_${this.restaurantIdentifier}`;
    }

    /**
     * This method validates the item in local storage is there and has a valid structure.
     */
    private validateCartItems() {
        const cartItem = window.localStorage.getItem(this.getName()) as string;

        let cartObj: ICART = { cartItem: [] };

        if (!cartItem) {
            return localStorage.setItem(
                this.getName(),
                JSON.stringify(cartObj)
            );
        }

        try {
            cartObj = JSON.parse(cartItem);
        } catch (e) {
            console.log(e);
            return localStorage.setItem(
                this.getName(),
                JSON.stringify(cartObj)
            );
        }

        if (!cartObj.cartItem || !Array.isArray(cartObj.cartItem)) {
            return localStorage.setItem(
                this.getName(),
                JSON.stringify(cartObj)
            );
        }
    }

    /**
     * This method create all the logic to add cart items.
     * @param {object} cartData - The ID of the item to set as cartItem.
     */
    addCartItem(cartData: any, setCount: boolean) {
        if (!isLocalStorageAvailable()) {
            return;
        }
        this.validateCartItems();
        const cartItem = window.localStorage.getItem(this.getName()) as string;
        const cartObj = JSON.parse(cartItem);

        const isPresent =
            cartObj?.cartItem?.length > 0 &&
            cartObj?.cartItem?.some((cartItem: any) =>
                cartData?.some((item: any) => item?._id === cartItem?._id)
            );
        const data: any = cartData?.length > 0 ? [...cartData] : [];
        cartObj.cartItem = !isPresent
            ? [...cartObj?.cartItem, ...data]
            : isPresent && setCount
            ? [...data]
            : cartObj?.cartItem;
        localStorage.setItem(this.getName(), JSON.stringify(cartObj));
        return cartObj?.cartItem;
    }

    /**
     * This method create all the logic to remove cart items.
     * @param {string} itemId - The ID of the cart item to remove.
     */
    removeCartItem(itemId: string) {
        if (!isLocalStorageAvailable()) {
            return;
        }
        this.validateCartItems();
        const cartItem: any = window.localStorage.getItem(this.getName());
        const cartObj = JSON.parse(cartItem);
        cartObj.cartItem = cartObj?.cartItem.filter(
            (cartItem: any) => cartItem?._id !== itemId
        );
        localStorage.setItem(this.getName(), JSON.stringify(cartObj));
        return cartObj?.cartItem;
    }

    /**
     * This method returns the cartItem array.
     */
    getCartItem() {
        if (!isLocalStorageAvailable()) {
            return;
        }
        this.validateCartItems();
        const cartItem = window.localStorage.getItem(this.getName()) as string;

        const cartObj = JSON.parse(cartItem);
        return cartObj?.cartItem;
    }

    clearCartItem() {
        if (!isLocalStorageAvailable()) {
            return;
        }
        let cartObj: ICART = { cartItem: [] };
        return window.localStorage.setItem(
            this.getName(),
            JSON.stringify(cartObj)
        );
    }
}
