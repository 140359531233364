/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Container } from "@mui/material";
import React, { useEffect } from "react";
import { Root, classes } from "./styles";
import { useNavigate } from "react-router-dom";
import LayoutOne from "../Components/Layout";
import { ICollections } from "../../../const";
import { useGlobalContext } from "../../../context/Global";
import {
    getRedirectFlag,
    getValidTimezone,
    matchSchedule,
    setRedirectFlag
} from "../../../utils/date";
import ShowSocialNetworks from "../Components/ShowSocialNetworks";
import { normalizeURL } from "../../../utils/url";
import _ from "lodash";
import LoadingScreen from "../../../SharedComponents/LoadingScreen";
import { loadImage } from "../../../utils/loadImage";

import { decode } from "blurhash";
import { Collections } from "../../../utils/collection";

export default function Restaurant1(props: any) {
    const navigate = useNavigate();

    const { fullMenu, setCollection, setMenu } = useGlobalContext();
    const data = fullMenu?.presenter_template;
    const [loading, setLoading] = React.useState(true);
    const [imgLoad, setImgLoad] = React.useState(true);

    const bodyWrapper = document.querySelector(".body-wrapper") as HTMLElement;

    React.useEffect(() => {
        setCollection && setCollection(null);
        setMenu && setMenu(null);

        if (_.get(fullMenu, "all_day_menu", true)) {
            // Only when there is just one collection, we redirect both to the splash page or the first menu
            if (fullMenu?.collection_links?.length === 1) {
                const collection = fullMenu.collection_links[0].collection_id;
                handleRedirect(collection);
            }
        }

        // If we have redirected before, we allow the users to see the page
        if (getRedirectFlag(fullMenu?.identifier || "")) {
            return;
        }

        // Scheduling
        if (fullMenu?.use_schedules) {
            const currentTime = new Date();
            const timezone = getValidTimezone(fullMenu?.timezone || "");

            // We use the try in case of the dates from the compiler.json are incorrect
            for (let schedule of fullMenu.collection_links) {
                try {
                    // If the current device time matches a schedule, we will redirect
                    const match = matchSchedule(
                        schedule.days,
                        schedule.start,
                        schedule.end,
                        currentTime,
                        timezone
                    );

                    if (match) {
                        const collectionId = schedule.collection_id._id;

                        // If the ID of the matched collection exists in the collections list, we redirect to it
                        const collectionToRedirect =
                            fullMenu.collection_links.filter(
                                (col) => col.collection_id._id === collectionId
                            );
                        if (collectionToRedirect.length) {
                            return handleRedirect(
                                collectionToRedirect[0].collection_id
                            );
                        }
                    }
                } catch (e) {
                    console.error("Error while scheduling ->", e);
                }
            }
        }

        if (fullMenu?.all_day_menu) {
            redirectToDefaultCollectionIfExists();
        }
    }, []);

    // Set initial blur background
    React.useEffect(() => {
        const urlString =
            fullMenu?.presenter_template.images?.restaurant_background_image;
        if (urlString) {
            const canvas = document.createElement("canvas");
            const ctx = canvas.getContext("2d");
            if (!ctx) {
                console.error("2D rendering not supported in this browser");
                return;
            }
            const blurParam = urlString.split("?blur=")[1];

            if (blurParam) {
                const pixels = decode(blurParam, 768, 768);

                const imageData = new ImageData(pixels, 768, 768);
                ctx.putImageData(imageData, 0, 0);

                const dataUrl = canvas.toDataURL();
                if (bodyWrapper) {
                    bodyWrapper.style.backgroundImage = `url(${dataUrl})`;
                    bodyWrapper.style.backgroundSize = "cover";
                }
                setLoading(false);
            }
        }
    }, []);

    // This effect takes care of the background color of the body
    React.useEffect(() => {
        // The backgroud image is only aplicable to the restaurant page
        if (fullMenu?.presenter_template.images?.restaurant_background_image)
            return fillImage();
        return fillColor();
    }, []);

    useEffect(() => {
        setTimeout(() => {
            setImgLoad(false);
        }, 500);
    }, [loading]);

    function fillColor() {
        const backgroundColor =
            fullMenu?.presenter_template.colors?.background_1 || "#fff";
        const className = "bg-" + backgroundColor;
        //  add class to body element
        document.body.classList.add(className);

        //  set style on body element
        bodyWrapper.style.backgroundColor = backgroundColor;
        setLoading(false);

        return () => {
            //  optionally remove styles when component unmounts
            bodyWrapper.style.backgroundColor = "";

            document.body.classList.remove(className);
        };
    }

    function setBodyStyles() {
        document.body.style.position = "relative";
    }

    function createImageDiv() {
        const imageDiv = document.createElement("div");
        imageDiv.style.opacity = "0";
        imageDiv.style.transition = "opacity 0.4s";
        imageDiv.style.position = "absolute";
        imageDiv.style.top = "0";
        imageDiv.style.left = "0";
        imageDiv.style.width = "100%";
        imageDiv.style.height = "100%";
        imageDiv.style.minHeight = "100vh";
        imageDiv.style.zIndex = "0";
        imageDiv.style.backgroundSize = "cover";
        imageDiv.id = "body-background";

        return imageDiv;
    }

    function loadImageAndApplyStyles(backgroundImage: any, imageDiv: any) {
        loadImage(
            backgroundImage,
            (image: any) => {
                if (!image) {
                    return;
                }
                // set style on imageDiv
                imageDiv.style.backgroundImage = `url(${image})`;
                imageDiv.style.opacity = "1";
                setLoading(false);
            },
            (error: any) => {
                console.log(error);
                imageDiv.style.backgroundImage = `url(${backgroundImage})`;
                imageDiv.style.opacity = "1";
                setLoading(false);
            }
        );
    }

    function cleanup(className: any, imageDivId: any) {
        const imageDiv = document.getElementById(imageDivId);
        imageDiv?.remove();

        bodyWrapper.style.backgroundImage = "";
        bodyWrapper.style.backgroundSize = "";
        document.body.classList.remove(className);
    }

    function fillImage() {
        const backgroundImage = `${fullMenu?.presenter_template.images?.restaurant_background_image}`;
        const className = "bg-" + backgroundImage;

        // add class to body element
        document.body.classList.add(className);
        setBodyStyles();
        const imageDiv = createImageDiv();
        loadImageAndApplyStyles(backgroundImage, imageDiv);
        bodyWrapper.appendChild(imageDiv);
        return () => {
            cleanup(className, imageDiv.id);
        };
    }

    function redirectToDefaultCollectionIfExists() {
        // If the ID of the matched collection does not exists, we redirect to the default collection
        const defaultCollectionToRedirect = fullMenu?.collection_links.filter(
            (col) => col.collection_id._id === fullMenu.default_collection
        );
        if (defaultCollectionToRedirect?.length) {
            return handleRedirect(defaultCollectionToRedirect[0].collection_id);
        }
    }

    const urlSearchParams = new URLSearchParams(window.location.search);
    const isOrderOn = urlSearchParams.get("order") === "on";

    function handleRedirect(collection: ICollections) {
        setRedirectFlag(fullMenu?.identifier || "");
        if (fullMenu?.presenter_template.controls?.show_splash_page) {
            return navigate(
                `/${fullMenu.identifier}/${collection._id}${
                    isOrderOn ? "?order=on" : ""
                }`,
                {
                    replace: true
                }
            );
        }
        const firstMenuId = collection.menu_links[0]?.menu_id._id;
        navigate(
            `/${fullMenu?.identifier}/${collection._id}/${firstMenuId}${
                isOrderOn ? "?order=on" : ""
            }`
        );
    }

    const collectionId = new Collections(`${fullMenu?.identifier}`);

    function onCollectionClick(collection: { collection_id: ICollections }) {
        collectionId.addCollectionId(`${collection.collection_id._id}`);
        if (fullMenu?.presenter_template.controls?.show_splash_page) {
            return navigate(
                `/${fullMenu.identifier}/${collection.collection_id._id}${
                    isOrderOn ? "?order=on" : ""
                }`
            );
        }
        const firstMenuId = collection.collection_id.menu_links[0]?.menu_id._id;
        return navigate(
            `/${fullMenu?.identifier}/${
                collection.collection_id._id
            }/${firstMenuId}${isOrderOn ? "?order=on" : ""}`
        );
    }

    function redirectToButtonLink(button_link: any) {
        if (button_link) {
            window.open(normalizeURL(button_link), "_blank");
        }
    }

    if (loading) {
        return <LoadingScreen></LoadingScreen>;
    }

    return (
        <Root
            template={data}
            style={
                (fullMenu?.available_languages?.length || 1) < 2
                    ? { paddingTop: "40px" }
                    : {}
            }
            className={`${classes.contentWrapper} ${
                imgLoad ? classes.imageLoading : classes.imageLoaded
            }`}
        >
            <LayoutOne
                headerImage={
                    fullMenu?.presenter_template.images?.restaurant_header_image
                }
                templateConfigs={{}}
                template={fullMenu?.presenter_template || {}}
                title={fullMenu?.display_name}
                showSearch={false}
                showNavbar={false}
                showNotationToggle={false}
            >
                <Container maxWidth="md" className={classes.gridContainer}>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center"
                        }}
                    >
                        {/* This is the landing message */}
                        {fullMenu?.landing_message && (
                            <h1
                                className={classes.landing_message}
                                dangerouslySetInnerHTML={{
                                    __html: fullMenu?.landing_message.replace(
                                        /\n/g,
                                        "<br />"
                                    )
                                }}
                            />
                        )}
                    </div>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center"
                        }}
                    >
                        {fullMenu?.button_one_active && (
                            <button
                                className={`${classes.landing_button} ${fullMenu?.button_one_position}`}
                                onClick={() =>
                                    redirectToButtonLink(
                                        fullMenu?.landing_button_link
                                    )
                                }
                            >
                                {fullMenu?.landing_button_text}
                            </button>
                        )}
                        {fullMenu?.button_two_active && (
                            <button
                                className={`${classes.landing_button_2} ${fullMenu?.button_two_position}`}
                                onClick={() =>
                                    redirectToButtonLink(
                                        fullMenu?.landing_button_two_link
                                    )
                                }
                            >
                                {fullMenu?.landing_button_two_text}
                            </button>
                        )}
                        {fullMenu?.button_three_active && (
                            <button
                                className={`${classes.landing_button_3} ${fullMenu?.button_three_position}`}
                                onClick={() =>
                                    redirectToButtonLink(
                                        fullMenu?.landing_button_three_link
                                    )
                                }
                            >
                                {fullMenu?.landing_button_three_text}
                            </button>
                        )}
                        {fullMenu?.collection_links?.map((collection) => (
                            <div
                                style={{
                                    order: 2
                                }}
                                key={collection.collection_id._id}
                                className={classes.collectionContainer}
                                onClick={() => onCollectionClick(collection)}
                            >
                                <h4 className={classes.secondaryMessage}>
                                    {collection.collection_id.display_name}
                                </h4>
                                <p className={classes.description}>
                                    {
                                        collection.collection_id
                                            .schedule_landing_message
                                    }
                                </p>
                            </div>
                        ))}
                    </Box>
                    <ShowSocialNetworks
                        template={fullMenu?.presenter_template || {}}
                    ></ShowSocialNetworks>
                </Container>
            </LayoutOne>
        </Root>
    );
}
