import { styled } from "@mui/material/styles";
import { IPresentTemplate } from "../../../../const";
import { Grid } from "@mui/material";

type Props = {
    template?: Partial<IPresentTemplate>;
};

const classes = {
    body: `body`,
    menu: `menu`,
    header: `header`,
    title: `title`,
    subtitle: `subtitle`,
    main: `main`
};

const Root = styled(Grid)<Props>(({ template }) => ({
    [`&.${classes.body}`]: {},
    [`& .${classes.menu}`]: {},
    [`& .${classes.header}`]: {
        margin: "25px 0 25px 0"
    },
    [`& .${classes.title}`]: {
        fontFamily: template?.fonts?.restaurant?.family || "Inter",
        fontWeight: template?.fonts?.restaurant?.weight || "400",
        fontSize: template?.fonts?.restaurant?.size || "1.7rem",
        textAlign: "center",
        lineHeight: template?.fonts?.restaurant?.line_height || ".8",
        color: template?.fonts?.restaurant?.color || "#000000",
        "&:hover": {
            cursor: "pointer"
        }
    },
    [`& .${classes.subtitle}`]: {
        fontFamily: template?.fonts?.collection?.family || "Inter",
        color: template?.fonts?.collection?.color || "#000000",
        textAlign: "center",
        fontSize: template?.fonts?.collection?.size || "1.2rem",
        fontWeight: template?.fonts?.collection?.weight || "400",
        marginTop: "-11px",
        "&:hover": {
            cursor: "pointer"
        }
    },
    [`& .${classes.main}`]: {}
}));

export { Root, classes };
