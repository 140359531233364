import { COLLECTION_ID } from "../const";
import { isLocalStorageAvailable } from "./localStorage";

export class Collections {
    restaurantIdentifier: string;
    constructor(restaurantIdentifier: string) {
        this.restaurantIdentifier = restaurantIdentifier;
    }

    private getName() {
        return `${COLLECTION_ID}_${this.restaurantIdentifier}`;
    }

    /**
     * This method validates the collectionId in local storage is there and has a valid structure.
     */
    private validateCollection() {
        const collationId = window.localStorage.getItem(
            this.getName()
        ) as string;

        if (!collationId) {
            return localStorage.setItem(this.getName(), "");
        }
    }

    /**
     * This method create all the logic to add collection Id.
     * @param {string} collectionId - The ID of the collection to set as collectionId.
     */
    addCollectionId(collectionId: string) {
        if (!isLocalStorageAvailable()) {
            return;
        }
        this.validateCollection();
        localStorage.setItem(this.getName(), collectionId);

        return collectionId;
    }

    /**
     * This method returns the collection Id.
     */
    getcollection() {
        if (!isLocalStorageAvailable()) {
            return;
        }
        const collectionId = window.localStorage.getItem(
            this.getName()
        ) as string;

        return collectionId;
    }
}
