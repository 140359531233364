/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import { Root, classes } from "./styles";
import { useNavigate } from "react-router-dom";
import { Icon } from "@mui/material";
import { ArrowBack, ArrowForward } from "@mui/icons-material";
import { useGlobalContext } from "../../../../context/Global";
import { IFullMenu } from "../../../../const";

const NavbarOne = (props: { restaurant?: IFullMenu; useAsHeader: boolean }) => {
    const { restaurant, useAsHeader } = props;
    const { fullMenu, collection } = useGlobalContext();
    const navigate = useNavigate();
    const navbarScroller = React.useRef(null);

    const [showRightArrow, setShowRightArrow] = React.useState<boolean>(false);
    const [showLeftArrow, setShowLeftArrow] = React.useState<boolean>(false);
    React.useEffect(() => {
        scrollHandler();

        window.addEventListener("resize", scrollHandler);

        return () => {
            window.removeEventListener("resize", scrollHandler);
        };
    }, [navbarScroller.current]);

    function scrollHandler() {
        // This function needs to be call on every scroll, and it decides if show or not the scroll arrows
        setShowRightArrow(hasHorizontalScrollbar() && !hasFinishScroll());
        setShowLeftArrow(hasHorizontalScrollbar() && !isOnTheLeft());
    }

    function hasHorizontalScrollbar() {
        // The navbar has horizontal scroll
        if (!navbarScroller.current) return false;

        const element: any = navbarScroller.current;
        return element.scrollWidth > element.clientWidth;
    }

    function isOnTheLeft() {
        // The navbar is completely on the left
        if (!navbarScroller.current) return false;

        const element: any = navbarScroller.current;
        return element.scrollLeft === 0;
    }

    function hasFinishScroll() {
        // The navbar is completely on the right
        if (!navbarScroller.current) return false;

        const element: any = navbarScroller.current;
        return element.scrollWidth - element.scrollLeft === element.clientWidth;
    }

    function onScroll(direction: "right" | "left") {
        if (!navbarScroller.current) return false;

        const element: any = navbarScroller.current;
        element.scrollLeft += direction === "right" ? 30 : -30;
    }

    return (
        <Root
            position="static"
            className={classes.appBar}
            style={{
                backgroundColor:
                    fullMenu?.presenter_template.navbar_color || "blueviolet",
                position: "absolute",
                top: useAsHeader ? "40px" : "85px",
                left: 0
            }}
        >
            <Container maxWidth="xl" style={{ padding: "0 0 0 0" }}>
                <Toolbar>
                    {showLeftArrow && !useAsHeader && (
                        <div style={{ width: "20px", marginRight: "5px" }}>
                            {""}
                            <Icon
                                color="action"
                                onClick={() => onScroll("left")}
                            >
                                <ArrowBack></ArrowBack>
                            </Icon>
                        </div>
                    )}
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: useAsHeader ? "center" : "center",
                            alignItems: "center",
                            height: "53px",
                            width: useAsHeader ? "100%" : "calc( 100% - 40px )",
                            overflowX: "auto",
                            overflowY: "hidden"
                        }}
                    >
                        {useAsHeader ? (
                            <h1
                                className={classes.navbarTitle}
                                style={{
                                    color: fullMenu?.presenter_template
                                        .navbar_text_color,
                                    fontFamily:
                                        fullMenu?.presenter_template.fonts
                                            ?.restaurant?.family,
                                    fontWeight: 500
                                }}
                                onClick={() => {}}
                            >
                                {fullMenu?.display_name}
                            </h1>
                        ) : (
                            <div
                                style={{
                                    width: "100%",
                                    display: "flex",
                                    flexDirection: "row"
                                    //justifyContent:"space-evenly"
                                }}
                            >
                                <div
                                    ref={navbarScroller}
                                    onScroll={scrollHandler}
                                    style={{
                                        margin: "auto",
                                        overflowX: "auto",
                                        display: "flex"
                                    }}
                                >
                                    {collection?.collection_id?.menu_links.map(
                                        (item) => (
                                            <Button
                                                key={item.menu_id._id}
                                                onClick={() => {
                                                    navigate(
                                                        `/${restaurant?.identifier}/${collection?.collection_id?._id}/${item.menu_id._id}`
                                                    );
                                                }}
                                                style={{
                                                    color: fullMenu
                                                        ?.presenter_template
                                                        .navbar_text_color,
                                                    fontFamily:
                                                        fullMenu
                                                            ?.presenter_template
                                                            .fonts?.menu_links
                                                            ?.family,
                                                    fontWeight: "600",
                                                    fontSize: "1.2rem",
                                                    textTransform: "unset",
                                                    padding: "0 20px 0 20px",
                                                    marginTop: "0",
                                                    marginBottom: "0",
                                                    whiteSpace: "nowrap",
                                                    flex: "0 0 auto",
                                                    margin: "auto"
                                                }}
                                            >
                                                {item.menu_id.display_name}
                                            </Button>
                                        )
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                    {showRightArrow && !useAsHeader && (
                        <div style={{ width: "20px", paddingLeft: "5px" }}>
                            <Icon
                                color="action"
                                onClick={() => onScroll("right")}
                            >
                                <ArrowForward></ArrowForward>
                            </Icon>
                        </div>
                    )}
                </Toolbar>
            </Container>
        </Root>
    );
};
export default NavbarOne;
