import { styled } from "@mui/material/styles";

const classes = {
    footer: `footer`,
    footerContainer: `footerContainer`,
    footerCustomMessage: `footerCustomMessage`,
    footerMessage: `footerMessage`,
    footerMessage2: `footerMessage2`
};

const Root = styled("footer")(() => ({
    [`&.${classes.footer}`]: {
        width: "100%",
        height: "118px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    [`& .${classes.footerContainer}`]: {},
    [`& .${classes.footerCustomMessage}`]: {
        fontFamily: "Inter",
        fontSize: "9pt",
        fontWeight: "200",
        textAlign: "center",
        color: "#22333b",
        marginBottom: "40px"
    },
    [`& .${classes.footerMessage}`]: {
        fontFamily: "Inter",
        fontSize: "9pt",
        fontWeight: "200",
        textAlign: "center",
        color: "#22333b"
    },
    [`& .${classes.footerMessage2}`]: {
        fontFamily: "Inter",
        fontSize: "9pt",
        fontWeight: "200",
        textAlign: "center",
        color: "#22333b",
        marginTop: "-10px"
    }
}));

export { Root, classes };
