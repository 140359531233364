import { Container } from "@mui/system";
import React, { useEffect, useState } from "react";
import { Root, classes } from "./styles";
import { loadImage } from "../../../../utils/loadImage";

export default function ShowImage(props: {
    imageURL: string;
    altName?: string;
    onClick?: () => void;
    style?: { [key: string]: string };
}) {
    const { imageURL, altName, style, onClick } = props;
    const [imageLoaded, setImageLoaded] = useState(false);
    const [imgSrc, setImgSrc] = useState("");

    useEffect(() => {
        const blobImageURL = imageURL;
        loadImage(
            blobImageURL,
            (image: any) => {
                setImgSrc(image);
            },
            (error: any) => {
                console.log(error);
                setImgSrc(blobImageURL);
            }
        );
    }, [imageURL]);

    const handleImageLoad = () => {
        setImageLoaded(true);
    };

    return (
        <Root maxWidth="md" className={classes.logoContainer}>
            <Container maxWidth="md" className={classes.logoContainerWrap}>
                <img
                    src={imgSrc}
                    alt={altName + " logo"}
                    className={`${classes.logo} ${
                        imageLoaded ? classes.imageLoaded : classes.imageLoading
                    }`}
                    style={style || {}}
                    onLoad={handleImageLoad}
                    onClick={() => onClick && onClick()}
                ></img>
            </Container>
        </Root>
    );
}
