// /* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import Button from "@mui/material/Button";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Box } from "@mui/material";
import { useGlobalContext } from "../../../../context/Global";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import KeyboardDoubleArrowRightOutlinedIcon from "@mui/icons-material/KeyboardDoubleArrowRightOutlined";
import SearchBar from "../Search";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";

const Navbar3 = (props: { showSearch: boolean }) => {
    const {
        fullMenu,
        collection,
        allFavorites,
        setSearch,
        setFilteredItems,
        search
    } = useGlobalContext();
    const [showInput, setShowInput] = React.useState(false);
    const [selectFavorite, setSelectFavorite] = React.useState(false);
    const { menu: menuId } = useParams();
    const [currentSearch, setCurrentSearch] = React.useState<string>("");
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const path = pathname.split("/")[2];

    React.useEffect(() => {
        if (!currentSearch) {
            setSearch && setSearch("");
            setFilteredItems && setFilteredItems([]);
        }
    }, [currentSearch, setFilteredItems, setSearch]);

    React.useEffect(() => {
        if (!search) {
            setCurrentSearch("");
        }
    }, [search]);

    function isSelected(id: string) {
        return id === menuId;
    }

    const handleFontWeight = (item: string): string | number => {
        const menuLinkWeight =
            fullMenu!.presenter_template.fonts?.menu_links?.weight || "400";

        if (isSelected(item)) {
            return parseInt(menuLinkWeight) + 200;
        } else {
            return menuLinkWeight;
        }
    };

    return (
        <AppBar
            position="sticky"
            sx={{
                background: `${fullMenu?.presenter_template?.colors?.secondary}`,
                display: "flex",
                flexDirection: "row",
                "& ::-webkit-scrollbar": {
                    width: "0px",
                    height: "0px",
                    borderRadius: "0px",
                    backgroundColor: "transparent",
                    boxShadow: "0 0 1px transparent",
                    display: "none"
                },
                "& ::-webkit-scrollbar-thumb": {
                    borderRadius: "0px",
                    backgroundColor: "transparent",
                    boxShadow: " 0 0 1px transparent"
                },
                height: "60px",
                alignItems: "center",
                justifyContent: "space-evenly"
            }}
            elevation={0}
        >
            {!showInput && (
                <Container
                    sx={{
                        overflowX: "scroll",
                        width: "100%",
                        p: 0,
                        ml: 0,
                        pl: 0,
                        pr: 0,
                        "&.MuiContainer-root": {
                            p: 0,
                            ml: "15px"
                        }
                    }}
                >
                    <Toolbar disableGutters>
                        <Box
                            sx={{
                                flexGrow: !showInput ? 1 : 0,
                                display: { xs: "flex", md: "none" }
                            }}
                        ></Box>

                        <Box
                            sx={{
                                flexGrow: !showInput ? 1 : 0,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center"
                            }}
                        >
                            {!showInput &&
                                collection?.collection_id?.menu_links?.map(
                                    (item) => {
                                        return (
                                            <Button
                                                sx={{
                                                    "&.MuiButtonBase-root": {
                                                        textTransform: "none"
                                                    },
                                                    overflow: "hidden",
                                                    textOverflow: "ellipsis",
                                                    whiteSpace: "nowrap",
                                                    textAlign: "left",
                                                    color: fullMenu
                                                        ?.presenter_template
                                                        .fonts?.menu_links
                                                        ?.color,
                                                    display: "block",
                                                    borderBottom: `${
                                                        isSelected(
                                                            item.menu_id._id
                                                        )
                                                            ? "2px solid"
                                                            : "1px solid"
                                                    } ${
                                                        isSelected(
                                                            item.menu_id._id
                                                        )
                                                            ? fullMenu
                                                                  ?.presenter_template
                                                                  .fonts
                                                                  ?.menu_links
                                                                  ?.color
                                                            : fullMenu
                                                                  ?.presenter_template
                                                                  .fonts
                                                                  ?.menu_links
                                                                  ?.color
                                                    }`,
                                                    borderRadius: 0,
                                                    fontFamily:
                                                        fullMenu
                                                            ?.presenter_template
                                                            ?.fonts?.menu_links
                                                            ?.family ||
                                                        "'Oswald', sans-serif",
                                                    fontWeight:
                                                        handleFontWeight(
                                                            item.menu_id._id
                                                        ),

                                                    fontSize:
                                                        fullMenu
                                                            ?.presenter_template
                                                            ?.fonts?.menu_links
                                                            ?.size || "1.62rem",
                                                    lineHeight:
                                                        fullMenu
                                                            ?.presenter_template
                                                            ?.fonts?.menu_links
                                                            ?.line_height ||
                                                        "1rem",

                                                    pl: "13px",
                                                    pr: "13px",
                                                    pb: 3,
                                                    height:
                                                        fullMenu
                                                            ?.presenter_template
                                                            ?.fonts?.menu_links
                                                            ?.size || "1.62rem",
                                                    fontStyle:
                                                        fullMenu
                                                            ?.presenter_template
                                                            ?.fonts?.menu_links
                                                            ?.style || "normal"
                                                }}
                                                key={item.menu_id._id}
                                                onClick={() => {
                                                    navigate(
                                                        `/${fullMenu?.identifier}/${collection?.collection_id?._id}/${item.menu_id._id}`
                                                    );
                                                    setSelectFavorite(false);
                                                }}
                                            >
                                                {item.menu_id.display_name}
                                            </Button>
                                        );
                                    }
                                )}
                            {allFavorites!.length > 0 && !showInput && (
                                <Button
                                    sx={{
                                        "&.MuiButtonBase-root": {
                                            textTransform: "none"
                                        },
                                        color: fullMenu?.presenter_template
                                            ?.fonts?.menu_links?.color,
                                        textAlign: "left",
                                        display: "block",
                                        borderBottom:
                                            path === "favorites"
                                                ? `2px solid ${fullMenu?.presenter_template.fonts?.menu_links?.color}`
                                                : `1px solid ${fullMenu?.presenter_template.fonts?.menu_links?.color}`,
                                        borderRadius: 0,
                                        fontFamily:
                                            fullMenu?.presenter_template?.fonts
                                                ?.menu_links?.family ||
                                            "'Oswald', sans-serif",
                                        fontWeight:
                                            path === "favorites"
                                                ? parseInt(
                                                      fullMenu
                                                          ?.presenter_template
                                                          ?.fonts?.menu_links
                                                          ?.weight as string
                                                  ) + 200
                                                : 400,
                                        fontSize:
                                            fullMenu?.presenter_template?.fonts
                                                ?.menu_links?.size || "1.62rem",
                                        lineHeight:
                                            fullMenu?.presenter_template?.fonts
                                                ?.menu_links?.line_height ||
                                            "1rem",
                                        height:
                                            fullMenu?.presenter_template.fonts
                                                ?.menu_links?.size || "1.62rem",
                                        pl: "13px",
                                        pr: "13px",
                                        pb: 3,
                                        fontStyle:
                                            fullMenu?.presenter_template.fonts
                                                ?.menu_links?.style || "normal"
                                    }}
                                    onClick={() => {
                                        navigate(
                                            `/${fullMenu?.identifier}/favorites`
                                        );
                                        setSelectFavorite(!selectFavorite);
                                    }}
                                >
                                    Favorites
                                </Button>
                            )}
                        </Box>
                    </Toolbar>
                </Container>
            )}
            {!showInput && props.showSearch ? (
                <Button
                    onClick={() => setShowInput(!showInput)}
                    sx={{
                        color: fullMenu?.presenter_template?.fonts?.menu_links
                            ?.color,
                        ":hover": {
                            background: "none"
                        }
                    }}
                >
                    <SearchOutlinedIcon
                        sx={{
                            width: "30px",
                            height: "30px"
                        }}
                        fontSize={"large"}
                    />
                </Button>
            ) : (
                props.showSearch && (
                    <>
                        <Button
                            onClick={() => {
                                setShowInput(!showInput);
                                setFilteredItems && setFilteredItems([]);
                                setCurrentSearch("");
                                setSearch && setSearch("");
                            }}
                            sx={{
                                ":hover": {
                                    background: "none"
                                },
                                ml: "7px",
                                p: 0
                            }}
                        >
                            <KeyboardDoubleArrowRightOutlinedIcon
                                sx={{
                                    width: "30px",
                                    height: "30px",
                                    color: fullMenu?.presenter_template.fonts
                                        ?.menu_links?.color,
                                    ":hover": {
                                        color: "black"
                                    }
                                }}
                            />
                        </Button>
                        <SearchBar />
                    </>
                )
            )}
        </AppBar>
    );
};
export default Navbar3;
