/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useNavigate } from "react-router-dom";
import { useGlobalContext } from "../../../context/Global";
import LoadingScreen from "../../../SharedComponents/LoadingScreen";

export default function WebCollection1() {
    const { fullMenu, collection } = useGlobalContext();
    const navigate = useNavigate();

    React.useEffect(() => {
        const menus = collection.collection_id?.menu_links;
        if (fullMenu && collection && Array.isArray(menus) && menus.length) {
            return navigate(
                `/web/${fullMenu.identifier}/${collection.collection_id?._id}/${menus[0].menu_id?._id}`
            );
        }
    }, [fullMenu, collection]);

    return <LoadingScreen></LoadingScreen>;
}
