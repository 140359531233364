/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import Cookie from "js-cookie";
import LoadingScreen from "../LoadingScreen";
import {
    PATRON_COOKIE_EXPIRE,
    PATRON_COOKIE_NAME,
    QR_ID_FLAG,
    QR_SCANNED_FLAG
} from "../../const/events";
import { useSearchParams } from "react-router-dom";
import { useTrackContext } from "../../context/Tracking";
import { useGlobalContext } from "../../context/Global";
import { Section } from "../../utils/sections";

/**
 * This component will set 3 values into the global context: the fullMenu, collection, and menu.
 * The only necessary condition is that they exists, otherwise is going to redirect to the listing page.
 */
export default function SetCookies(props: { children: any }) {
    const [loading, setLoading] = React.useState(true);
    const [searchParams, setSearchParams] = useSearchParams();
    const { newEvent } = useTrackContext();
    const { fullMenu: menu } = useGlobalContext();

    async function updateExpire() {
        Cookie.set(PATRON_COOKIE_NAME, Cookie.get()[PATRON_COOKIE_NAME], {
            domain: process.env.REACT_APP_COOKIE_DOMAIN,
            expires: PATRON_COOKIE_EXPIRE
        });
    }

    async function handleIsScanned() {
        if (!menu) {
            return;
        }
        const scanned = searchParams.get(QR_SCANNED_FLAG);
        const qrId = searchParams.get(QR_ID_FLAG);
        // When we have this query params, it means that this URL was redirect from the QR handler, so
        // we need to launch an event indicating a new QR scan and then remove the params to prevent
        // still sending this event at the time of reload the page.
        if (scanned || qrId) {
            const path = window.location.href;
            newEvent(
                "qr-scan",
                {
                    template: menu.presenter_template.template_id || "1",
                    name: scanned as string,
                    _id: qrId as string,
                    path
                },
                menu?.identifier as string
            );
            searchParams.delete(QR_SCANNED_FLAG);
            searchParams.delete(QR_ID_FLAG);
            setSearchParams(searchParams);
        }
    }

    React.useEffect(() => {
        if (Cookie.get()[PATRON_COOKIE_NAME]) {
            updateExpire();
        }
        handleIsScanned();
        setLoading(false);
    }, []);

    // With every page reload, we clean up the sections that were expanded
    React.useEffect(() => {
        const sections = new Section(`${menu?.identifier}`);
        sections.clearSections();
    }, []);

    return loading ? <LoadingScreen></LoadingScreen> : props.children;
}
