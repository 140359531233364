/* eslint-disable react-hooks/exhaustive-deps */
import { Grid } from "@mui/material";
import React from "react";
import { Root, classes } from "./styles";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import { useGlobalContext } from "../../../context/Global";
import Layout998 from "../Components/Layout";

export default function Restaurant998(props: any) {
    const navigate = useNavigate();

    const { fullMenu } = useGlobalContext();

    React.useEffect(() => {
        if (fullMenu?.collection_links?.length === 1) {
            navigate(
                `/${fullMenu.identifier}/${fullMenu.collection_links[0].collection_id._id}`,
                {
                    replace: true
                }
            );
        }
    }, []);

    return (
        <Layout998 templateConfigs={{}} showTitle={true}>
            <Root>
                <Grid container>
                    <Grid
                        item
                        xs={12}
                        className={classes.secondaryMessageContainer}
                    >
                        {fullMenu?.collection_links?.map((collection) => (
                            <React.Fragment key={collection.collection_id._id}>
                                <h4
                                    className={classes.secondaryMessage}
                                    onClick={() => {
                                        navigate(
                                            `/${fullMenu.identifier}/${collection.collection_id._id}`
                                        );
                                    }}
                                >
                                    {collection.collection_id.display_name}
                                </h4>
                                <p className={classes.description}>
                                    {
                                        collection.collection_id
                                            .schedule_landing_message
                                    }
                                </p>
                            </React.Fragment>
                        ))}
                    </Grid>
                </Grid>
                <Helmet>
                    <title>{fullMenu?.display_name} - Stellar Menus</title>
                </Helmet>
            </Root>
        </Layout998>
    );
}
