/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Card, CardMedia, Grid } from "@mui/material";
import React from "react";
import { Root, classes } from "./styles";
import FooterThree from "../Footer";
import { useGlobalContext } from "../../../../context/Global";
import "./index.css";
import { useNavigate } from "react-router-dom";
import Navbar3 from "../Navbar";
import LanguageSelector from "../../../../SharedComponents/LanguageSelector";

export default function LayoutThree(props: {
    templateConfigs: any;
    title?: string;
    subtitle?: string;
    showSearch: boolean;
    showNavbar: boolean;
    children: React.ReactElement;
}) {
    const { children, showSearch } = props;

    const { fullMenu, menu } = useGlobalContext();
    const navigate = useNavigate();

    const data = fullMenu?.presenter_template;

    return (
        <Root
            template={data}
            container
            className={classes.body}
            sx={
                {
                    // p: {
                    //     lg: "0px 25rem 0px 25rem",
                    //     md: "0px 10rem 0px 10rem",
                    //     xl: "0px 30rem 0px 30rem"
                    // }
                }
            }
        >
            <Grid item xs={12}>
                <LanguageSelector
                    bgColor={fullMenu?.presenter_template.colors?.background_2}
                />
                <div className={classes.header}>
                    {/* The title */}
                    {props.title!.length > 60 ? (
                        <Card sx={{ borderRadius: 0 }}>
                            <CardMedia
                                component="img"
                                height="auto"
                                image={props.title}
                                alt="image"
                            />
                        </Card>
                    ) : (
                        <Box
                            onClick={() => navigate(`/${fullMenu?.identifier}`)}
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                background:
                                    fullMenu?.presenter_template.colors
                                        ?.background_2,
                                width: "100%",
                                height: "100px",
                                color: fullMenu?.presenter_template?.fonts
                                    ?.restaurant?.color,
                                fontFamily:
                                    fullMenu?.presenter_template?.fonts
                                        ?.restaurant?.name ||
                                    fullMenu?.presenter_template?.fonts
                                        ?.restaurant?.family,
                                fontSize:
                                    fullMenu?.presenter_template?.fonts
                                        ?.restaurant?.size || "3.25rem",
                                textAlign: "center",
                                fontWeight:
                                    fullMenu?.presenter_template?.fonts
                                        ?.restaurant?.weight,
                                fontStyle:
                                    fullMenu?.presenter_template?.fonts
                                        ?.restaurant?.style,
                                lineHeight:
                                    fullMenu?.presenter_template?.fonts
                                        ?.restaurant?.line_height || "1rem"
                            }}
                        >
                            {props.title}
                        </Box>
                    )}
                </div>
            </Grid>
            <Navbar3 showSearch={showSearch}></Navbar3>
            <Grid item xs={12}>
                <main className={classes.main}>{children}</main>
            </Grid>
            <FooterThree
                footerMessage={menu?.menu_id?.footer_text}
            ></FooterThree>
        </Root>
    );
}
