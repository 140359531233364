/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Container, Grid } from "@mui/material";
import React from "react";
import { useGlobalContext } from "../../../context/Global";
import LayoutOne from "../Components/Layout";
import { filterItems } from "../../../utils/filter";
import NoSearchResults from "../Components/NoSearchResult";
import Section from "../Components/Section";
import { Favorites } from "../../../utils/favorites";
import { IItem } from "../../../const";
import { useTrackContext } from "../../../context/Tracking";
import { useNavigate } from "react-router-dom";
import { Collections } from "../../../utils/collection";
import { useIntl } from "react-intl";

export default function Favorite1(props: any) {
    const {
        fullMenu,
        collection,
        allFavorites,
        setAllFavorites,
        search,
        showNotations
    } = useGlobalContext();
    const [filteredItems, setFiteredItems] = React.useState<
        Array<{
            item_id: IItem;
        }>
    >([]);
    const { newEvent } = useTrackContext();
    const navigate = useNavigate();

    const intl = useIntl();

    React.useEffect(() => {
        if (search?.length) {
            //If there is an active search, look for items in the entire collection
            let items: Array<{
                item_id: IItem;
            }> = [];

            // Filter over collections - menus - sections
            collection?.collection_id?.menu_links.forEach((_menu) => {
                _menu?.menu_id?.section_links?.forEach((section) => {
                    items = [
                        ...items,
                        ...filterItems(
                            section.section_id.item_links,
                            search || ""
                        )
                    ];
                });
            });

            return setFiteredItems(getUniqueItems(items));
        }
        // If there is not a search, just return the favorite ones
        setFiteredItems(getUniqueItems(getFavorites()));
    }, [search]);

    // This effect takes care of the background color of the body
    const bodyWrapper = document.querySelector(".body-wrapper") as HTMLElement;
    React.useEffect(() => {
        const backgroundColor =
            fullMenu?.presenter_template.colors?.background_1 || "#fff";
        const className = "bg-" + backgroundColor;
        //  add class to body element
        document.body.classList.add(className);

        //  set style on body element
        bodyWrapper.style.backgroundColor = backgroundColor;

        return () => {
            //  optionally remove styles when component unmounts
            bodyWrapper.style.backgroundColor = "";

            document.body.classList.remove(className);
        };
    }, []);

    React.useEffect(() => {
        newEvent(
            "bookmark-view",
            {
                template: "1",
                bookmark: getFavorites().length
            },
            fullMenu?.identifier || ""
        );
    }, []);

    function getUniqueItems(
        items: {
            item_id: IItem;
        }[]
    ): Array<{
        item_id: IItem;
    }> {
        return items.filter(
            (v, i, a) =>
                a.findIndex((t) => t.item_id._id === v.item_id._id) === i
        );
    }

    function getFavorites(): Array<any> {
        const _favorites: any = [];
        fullMenu?.collection_links.forEach((col) => {
            col.collection_id.menu_links.forEach((men) => {
                men.menu_id.section_links.forEach((sec) => {
                    sec.section_id.item_links.forEach((it) => {
                        if (allFavorites?.indexOf(it.item_id?._id) !== -1) {
                            _favorites.push(it);
                        }
                    });
                });
            });
        });

        return _favorites;
    }

    function clearfavorites() {
        setAllFavorites && setAllFavorites([]);
        const favorites = new Favorites(`${fullMenu?.identifier}`);
        favorites.clearFavorite();
        setFiteredItems([]);

        newEvent(
            "clear-bookmark",
            {
                template: "1"
            },
            fullMenu?.identifier || ""
        );

        const collectionId = new Collections(`${fullMenu?.identifier}`);
        const current_collection = collectionId.getcollection();

        const matchingObject = fullMenu?.collection_links.find(
            (item) => item?.collection_id?._id === current_collection
        );

        const firstMenuId =
            matchingObject?.collection_id.menu_links[0]?.menu_id._id ||
            fullMenu?.collection_links[0]?.collection_id.menu_links[0]?.menu_id
                ._id;

        const currentCollection =
            matchingObject?.collection_id._id ||
            fullMenu?.collection_links[0]?.collection_id._id;

        const navigateUrl = `/${fullMenu?.identifier}/${currentCollection}/${firstMenuId}`;

        return navigate(navigateUrl);
    }

    return (
        <LayoutOne
            title={fullMenu?.display_name}
            subtitle={collection?.collection_id?.display_name}
            showSearch
            showNavbar
            showNotationToggle
            templateConfigs={{}}
            template={fullMenu?.presenter_template || {}}
        >
            <Container maxWidth="md" style={{ padding: "0 0 0 0" }}>
                <Grid container>
                    <Grid item xs={12}>
                        <Section
                            sectionTitle={
                                search
                                    ? intl.formatMessage({
                                          id: "items.search_result"
                                      })
                                    : intl.formatMessage({
                                          id: "favorites.title"
                                      })
                            }
                            sectionDescription=""
                            defaultExpanded
                            alwaysExpanded
                            showNotations={showNotations}
                            items={filteredItems}
                            id="Favorites-section"
                            internalName="Favorites-section"
                            template={fullMenu?.presenter_template || {}}
                        ></Section>
                        {/* If there are favorites, show the option to remove all */}
                        {Boolean(filteredItems.length) && !search && (
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    marginTop: "20px"
                                }}
                            >
                                <Button
                                    variant="contained"
                                    color="primary"
                                    style={{
                                        backgroundColor:
                                            fullMenu?.presenter_template.colors
                                                ?.primary,
                                        color: fullMenu?.presenter_template
                                            .fonts?.section_links?.color
                                    }}
                                    onClick={clearfavorites}
                                >
                                    {intl.formatMessage({
                                        id: "favorites.clear"
                                    })}
                                </Button>
                            </div>
                        )}
                        {/* There are not favorites yet */}
                        {!Boolean(filteredItems.length) && !search && (
                            <NoSearchResults isFavoritePage></NoSearchResults>
                        )}
                        {/* There are not results of searching */}
                        {!Boolean(filteredItems.length) && search && (
                            <NoSearchResults
                                isFavoritePage={false}
                            ></NoSearchResults>
                        )}
                    </Grid>
                </Grid>
            </Container>
        </LayoutOne>
    );
}
