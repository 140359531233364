import { styled } from "@mui/material/styles";
import { Container } from "@mui/system";
const classes = {
    logoContainer: `logoContainer`,
    logoContainerWrap: `logoContainerWrap`,
    logo: `logo`,
    imageLoaded: `imageLoaded`,
    imageLoading: `imageLoading`
};

const Root = styled(Container)(({ theme }) => ({
    [`&.${classes.logoContainer}`]: {
        display: "flex !important",
        justifyContent: "center",
        alignItems: "center",
        maxWidth: "225px !important",
        maxHeight: "180px"
    },
    [`& .${classes.logoContainerWrap}`]: {
        width: "100% !important",
        position: "relative",
        paddingBottom: "70%"
    },
    [`& .${classes.logo}`]: {
        cursor: "pointer",
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        objectFit: "contain",
        transition: "opacity 0.3s ease-in-out"
    },
    [`& .${classes.imageLoaded}`]: {
        opacity: 1
    },
    [`& .${classes.imageLoading}`]: {
        opacity: 0
    }
}));

export { Root, classes };
