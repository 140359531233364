// ------------ Tracking interfaces start ---------------
export interface TrackContextInterface {
    newEvent: <
        EVENT extends keyof typeof EVENT_PAYLOADS,
        PROPS extends (typeof EVENT_PAYLOADS)[EVENT]
    >(
        event: EVENT,
        params: PROPS,
        restaurantIdentifier: string
    ) => Promise<string>;
}

// The template is necessary in all events!
export const EVENT_PAYLOADS = {
    "patron-created": {
        template: ""
    },
    "qr-scan": {
        name: "",
        template: "",
        _id: "",
        path: ""
    },
    "menu-view": {
        _id: "",
        notations: true,
        expanded: true,
        name: "",
        template: ""
    },
    "collection-view": {
        _id: "",
        name: "",
        template: ""
    },
    "section-expand": {
        _id: "",
        name: "",
        template: ""
    },
    "item-favorite": {
        _id: "",
        name: "",
        template: ""
    },
    "item-unfavorite": {
        _id: "",
        name: "",
        template: ""
    },
    "item-image-click": {
        _id: "",
        name: "",
        template: "",
        image_url: ""
    },
    "item-button-click": {
        _id: "",
        name: "",
        template: "",
        button_url: ""
    },
    "search-performed": { name: "", template: "", items_returned: 1 },
    "bookmark-view": { template: "", bookmark: 1 },
    "clear-bookmark": { template: "" },
    "show-notations": { template: "", name: "" },
    "hide-notations": { template: "", name: "" },
    "change-language": { template: "", language: "" }
};

// ------------ Tracking interfaces end ---------------

export const QR_SCANNED_FLAG = "scanned";

export const QR_ID_FLAG = "id";

export const PATRON_COOKIE_NAME = "patron_id";

export const PATRON_COOKIE_EXPIRE = 365;

export const TRACK_VERSION = "v1.0.0";
