import { styled } from "@mui/material/styles";

const classes = {
    container: `container`
};

const Root = styled("div")(() => ({
    [`&.${classes.container}`]: {
        // width: "100vw",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    }
}));

export { Root, classes };
