/* eslint-disable react-hooks/exhaustive-deps */
import {
    Box,
    Card,
    CardMedia,
    Container,
    Grid,
    Skeleton,
    Typography
} from "@mui/material";
import React from "react";
import { useSearchParams } from "react-router-dom";
import { useGlobalContext } from "../../../context/Global";
import LayoutThree from "../Components/Layout";
import { filterItems } from "../../../utils/filter";
import NoSearchResults from "../Components/NoSearchResult";
import Section from "../Components/Section";
import BackToTop from "../../../SharedComponents/BackToTop";
import { Favorites } from "../../../utils/favorites";
import { IItem } from "../../../const";
import { useTrackContext } from "../../../context/Tracking";
import { useIntl } from "react-intl";

export default function Favorite2(props: any) {
    const { fullMenu, collection, allFavorites, search, setAllFavorites } =
        useGlobalContext();
    const [searchParams] = useSearchParams();
    const favorites = new Favorites(`${fullMenu?.identifier}`);
    const [showNotations, setShowNotations] = React.useState<boolean>(false);
    const [filteredItems, setFiteredItems] = React.useState<
        Array<{
            item_id: IItem;
        }>
    >([]);
    const { newEvent } = useTrackContext();
    const intl = useIntl();

    React.useEffect(() => {
        if (search?.length) {
            //If there is an active search, look for items in the entire collection
            let items: Array<{
                item_id: IItem;
            }> = [];

            // Filter over collections - menus - sections
            collection?.collection_id?.menu_links.forEach((_menu) => {
                _menu?.menu_id?.section_links?.forEach((section) => {
                    items = [
                        ...items,
                        ...filterItems(
                            section.section_id.item_links,
                            search || ""
                        )
                    ];
                });
            });

            return setFiteredItems(getUniqueItems(items));
        }
        // If there is not a search, just return the favorite ones
        setFiteredItems(getUniqueItems(getFavorites()));
    }, [search]);

    React.useEffect(() => {
        const notationState =
            searchParams.get("notations") === "true" ? true : false;
        setShowNotations(notationState);
    }, [searchParams, favorites]);

    React.useEffect(() => {
        newEvent(
            "bookmark-view",
            {
                template: "2",
                bookmark: getFavorites().length
            },
            fullMenu?.identifier || ""
        );
    }, []);

    function getUniqueItems(
        items: {
            item_id: IItem;
        }[]
    ): Array<{
        item_id: IItem;
    }> {
        return items.filter(
            (v, i, a) =>
                a.findIndex((t) => t.item_id._id === v.item_id._id) === i
        );
    }

    function getFavorites() {
        const _favorites: any = [];
        fullMenu?.collection_links.forEach((col) => {
            col.collection_id.menu_links.forEach((men) => {
                men.menu_id.section_links?.forEach((sec) => {
                    sec.section_id.item_links.forEach((it) => {
                        if (allFavorites?.indexOf(it.item_id?._id) !== -1) {
                            _favorites.push(it);
                        }
                    });
                });
            });
        });

        return _favorites;
    }

    const headerImage = fullMenu?.presenter_template.images?.header_image;
    const featuredImage = fullMenu?.presenter_template.images?.featured_image;

    const primaryBackgroundColor =
        fullMenu?.presenter_template?.colors?.primary;
    return (
        <>
            <LayoutThree
                title={headerImage ? headerImage : fullMenu?.display_name}
                subtitle={collection?.collection_id?.display_name}
                showSearch
                showNavbar
                templateConfigs={{}}
            >
                <Container
                    maxWidth="md"
                    sx={{
                        padding: 0,
                        backgroundImage: {
                            lg: `url(${featuredImage})`,
                            md: `url(${featuredImage})`,
                            sm: `url(${featuredImage})`,
                            xl: `url(${featuredImage})`
                        },
                        p: {
                            lg: 0,
                            md: 0,
                            xl: 0,
                            sm: 0
                        },
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        backgroundAttachment: "fixed"
                    }}
                >
                    <Grid container sx={{ p: 0 }}>
                        {featuredImage && (
                            <Box
                                sx={{
                                    height: "375px",
                                    width: "100%"
                                }}
                            >
                                {!featuredImage && (
                                    <Skeleton
                                        variant="rectangular"
                                        width={"100%"}
                                        height={"375px"}
                                    />
                                )}
                            </Box>
                        )}
                        <Grid item xs={12}>
                            <Section
                                sx={{
                                    background: `${
                                        primaryBackgroundColor
                                            ? primaryBackgroundColor
                                            : `#ffffff`
                                    }`
                                }}
                                sectionTitle={
                                    search
                                        ? intl.formatMessage({
                                              id: "items.search_result"
                                          })
                                        : intl.formatMessage({
                                              id: "favorites.title"
                                          })
                                }
                                sectionDescription=""
                                defaultExpanded
                                alwaysExpanded
                                showNotations={showNotations}
                                items={filteredItems}
                                id="Favorites-section"
                                internalName="Favorites-section"
                            ></Section>
                            <Box
                                sx={{
                                    display: "table",
                                    margin: "0 auto",
                                    background: `${
                                        primaryBackgroundColor
                                            ? primaryBackgroundColor
                                            : `#ffffff`
                                    }`,
                                    width: "100%",
                                    textAlign: "center",
                                    borderRadius: 0
                                }}
                                onClick={() => {
                                    favorites.clearFavorite();
                                    setAllFavorites && setAllFavorites([]);
                                    newEvent(
                                        "clear-bookmark",
                                        {
                                            template: "2"
                                        },
                                        fullMenu?.identifier || ""
                                    );
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontFamily:
                                            fullMenu?.presenter_template?.fonts
                                                ?.section_links?.family,
                                        fontWeight:
                                            fullMenu?.presenter_template?.fonts
                                                ?.section_links?.weight,
                                        fontSize:
                                            fullMenu?.presenter_template?.fonts
                                                ?.section_links?.size,
                                        fontStyle:
                                            fullMenu?.presenter_template?.fonts
                                                ?.section_links?.style,
                                        lineHeight:
                                            fullMenu?.presenter_template?.fonts
                                                ?.section_links?.line_height,
                                        color: fullMenu?.presenter_template
                                            ?.fonts?.section_links?.color,
                                        textDecoration: "underline",
                                        cursor: "pointer"
                                    }}
                                >
                                    {intl.formatMessage({
                                        id: "favorites.clear"
                                    })}
                                </Typography>
                            </Box>
                            {/* There are not favorites yet */}
                            {!filteredItems.length && !search && (
                                <NoSearchResults
                                    isFavoritePage
                                ></NoSearchResults>
                            )}
                            {/* There are not results of searching */}
                            {!filteredItems.length && search && (
                                <NoSearchResults
                                    isFavoritePage={false}
                                ></NoSearchResults>
                            )}
                        </Grid>
                        <Box
                            sx={{
                                left: "0px",
                                top: "0px",
                                overflow: "hidden",
                                margin: "0px",
                                padding: "0px",
                                height: "1037px",
                                width: "572px",
                                zIndex: -999999,
                                position: "fixed",
                                display: {
                                    xl: "none",
                                    lg: "none",
                                    sm: "none"
                                }
                            }}
                        >
                            <Card>
                                <CardMedia
                                    style={{
                                        position: "absolute",
                                        margin: "0px",
                                        padding: "0px",
                                        border: "none",
                                        width: "90%",
                                        height: "1231px",
                                        maxHeight: "none",
                                        maxWidth: "none",
                                        zIndex: -999999,
                                        top: "60px"
                                    }}
                                    image={featuredImage}
                                />
                            </Card>
                        </Box>
                    </Grid>
                    {
                        <BackToTop
                            backgroundColor={
                                fullMenu?.presenter_template.colors?.tertiary ||
                                "grey"
                            }
                            color={
                                fullMenu?.presenter_template.colors?.primary ||
                                "black"
                            }
                        ></BackToTop>
                    }
                </Container>
            </LayoutThree>
        </>
    );
}
