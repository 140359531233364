import React from "react";
import { Root, classes } from "./styles";
import { Grid } from "@mui/material";

export default function NotFound() {
    return (
        <Root>
            <Grid container className={classes.container}>
                <Grid item xs={12} className={classes.logoContainer}>
                    <img
                        className={classes.logo}
                        src="/stellar-menus-for-white-bg.png"
                        alt="Stellar Menus Logo"
                    ></img>
                </Grid>
                <Grid item xs={11} sm={11} md={9} lg={8} xl={7}>
                    <h1 className={classes.title}>
                        The menu you are trying to access is either blocked or
                        unavailable. If you are blocking third party cookies,
                        please allow third party cookies to view this menu.
                        (Error Message MP-404a)
                    </h1>
                </Grid>
            </Grid>
        </Root>
    );
}
