const baseUrl = process.env.REACT_APP_API_URL || "http://localhost:5000";

/**
 * This method allows the app to send HTTP request to the API.
 * @param {string} endpoint - The path of the endpont.
 * @param {object} params - The params needed, like body, custom headers or extra configurations.
 */
function client(
    endpoint: string,
    { body, customHeaders, ...customConfig } = {
        body: false,
        customHeaders: {}
    }
) {
    const config: RequestInit = {
        headers: {
            "Content-Type": "application/json",
            ...customHeaders
        },
        ...(body && { body: JSON.stringify(body) }),
        method: body ? "POST" : "GET",
        credentials: "same-origin",
        mode: "cors",
        ...customConfig
    };

    return window
        .fetch(`${baseUrl}/${endpoint}`, config)
        .then(async (response) => {
            const data = await response.json();
            if (response.ok) {
                return data;
            } else {
                return Promise.reject(data);
            }
        });
}

export { client };
