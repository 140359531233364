import { styled } from "@mui/material/styles";
import { IPresentTemplate } from "../../../const";
import { Container } from "@mui/material";
type Props = {
    template?: IPresentTemplate;
};

const classes = {
    gridContainer: `gridContainer`,
    collectionContainer: `collectionContainer`,
    secondaryMessage: `secondaryMessage`,
    description: `description`
};

const Root = styled(Container)<Props>(({ template }) => ({
    [`&.${classes.gridContainer}`]: {},
    [`& .${classes.collectionContainer}`]: {
        border: `1px solid ${template?.colors?.primary || "#CC5803"}`,
        boxShadow: `1px 2px 1px ${template?.colors?.primary || "#CC5803"}`,
        borderRadius: "15px",
        padding: "10px 0 10px 0",
        margin: "25px 0 25px 0",
        cursor: "pointer",
        transition: ".3s",
        "&:hover": {
            transform: "Scale(1.02)",
            backgroundColor: template?.colors?.primary || "#CC5803",
            color: "#fff"
        }
    },
    [`& .${classes.secondaryMessage}`]: {
        textAlign: "center",
        fontFamily: "Inter",
        fontSize: "1.2rem",
        fontWeight: "500",
        cursor: "pointer",
        lineHeight: "0"
    },
    [`& .${classes.description}`]: {
        fontFamily: "Inter",
        textAlign: "center",
        fontSize: ".9rem",
        fontWeight: "300"
    }
}));

export { Root, classes };
