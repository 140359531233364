import React from "react";
import { Helmet } from "react-helmet-async";
import { RestaurantFontProp } from "../const";
import { useGlobalContext } from "../context/Global";

/**
 * This component will import the necessary fonts specified in the object fullMenu?.presenter_template?.fonts.
 */
export default function FontImporter() {
    const { fullMenu } = useGlobalContext();
    const [fonts, setFonts] = React.useState<Array<RestaurantFontProp>>([]);

    // We create an array of fonts to import, taking care of not repite them.
    React.useEffect(() => {
        const alreadyImported = new Set();
        const fonts: any = fullMenu?.presenter_template?.fonts || {};
        for (const key in fonts) {
            const font: RestaurantFontProp = fonts[key];
            if (!alreadyImported.has(font.name)) {
                setFonts((f) => [...f, font]);
                alreadyImported.add(font.name);
            }
        }
    }, [fullMenu]);

    return (
        <Helmet>
            {fonts.map((font, i) => (
                <link
                    key={`${font.name}` + i}
                    href={font.url}
                    rel="stylesheet"
                ></link>
            ))}
        </Helmet>
    );
}
