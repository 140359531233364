import { styled } from "@mui/material/styles";
import { IPresentTemplate } from "../../../../const";
import { Grid } from "@mui/material";

type Props = {
    template?: Partial<IPresentTemplate>;
};

const classes = {
    body: `body`,
    menu: `menu`,
    header: `header`,
    title: `title`,
    subtitle: `subtitle`,
    main: `main`
};

const Root = styled(Grid)<Props>(({ template }) => ({
    [`&.${classes.body}`]: {},
    [`& .${classes.menu}`]: {},
    [`& .${classes.header}`]: {
        margin: "0"
    },
    [`& .${classes.title}`]: {
        fontFamily: "Inter",
        fontWeight: "400",
        fontSize: "1.7rem",
        textAlign: "center",
        lineHeight: ".8",
        "&:hover": {
            cursor: "pointer"
        }
    },
    [`& .${classes.subtitle}`]: {
        textAlign: "center",
        fontFamily: "Inter",
        fontSize: "1.2rem",
        fontWeight: "400",
        marginTop: "-11px",
        "&:hover": {
            cursor: "pointer"
        }
    },
    [`& .${classes.main}`]: {}
}));

export { Root, classes };
