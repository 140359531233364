/* eslint-disable react-hooks/exhaustive-deps */
import { Container } from "@mui/material";
import React from "react";
import { useParams } from "react-router-dom";
import { useGlobalContext } from "../../../context/Global";
import { useTrackContext } from "../../../context/Tracking";
import LayoutThree from "../Components/Layout";
import { Root, classes } from "./styles";

export function Collection2(props: any) {
    const { fullMenu, collection } = useGlobalContext();

    const { newEvent } = useTrackContext();
    const { restaurant: restaurantIdentifier } = useParams();

    React.useEffect(() => {
        if (
            typeof collection?.collection_id !== "undefined" &&
            restaurantIdentifier
        ) {
            newEvent(
                "collection-view",
                {
                    _id: collection.collection_id._id,
                    name: collection.collection_id.display_name,
                    template: "2"
                },
                restaurantIdentifier
            );
        } else {
            return;
        }
    }, [newEvent, collection]);

    const headerImage = fullMenu?.presenter_template.images?.header_image;

    return (
        <LayoutThree
            title={headerImage ? headerImage : fullMenu?.display_name}
            subtitle={collection?.collection_id?.display_name}
            showSearch={false}
            showNavbar
            templateConfigs={{}}
        >
            <Container maxWidth="md">
                <Root style={{ padding: "0 20px 0 20px" }}>
                    <div className={classes.welcomeMessage}>
                        {collection?.collection_id?.menu_landing_message}
                    </div>
                    <div className={classes.welcomeMessage}>
                        {collection?.collection_id?.menu_landing_message_2}
                    </div>
                    <div
                        className={classes.logoContainer}
                        style={{
                            display:
                                fullMenu?.presenter_template
                                    ?.showRestaurantImage && fullMenu.logo_image
                                    ? "flex"
                                    : "none"
                        }}
                    >
                        <img
                            className={classes.logo}
                            src={fullMenu?.logo_image}
                            alt="This needs to be changed."
                        />
                    </div>
                </Root>
            </Container>
        </LayoutThree>
    );
}
