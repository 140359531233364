import { IItem } from "../const";

/**
 * This method allows normalizes a specific string.
 * @param {string} str - The given string to normalize.
 */
function normalizeString(str: string) {
    return str
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .toLowerCase();
}

/**
 * This method allows normalizes a specific string.
 * @param {Array} items - The given items to filter.
 * @param {string} search - The given string to filter the items.
 */
export function filterItems(
    items: Array<{
        item_id: IItem;
    }>,
    search: string
) {
    const filteredItems: Array<{
        item_id: IItem;
    }> = [];
    const normalizedSearch = normalizeString(search);
    items.forEach((item) => {
        if (item?.item_id?.type === "title") {
            return;
        }
        if (
            // Name matches
            normalizeString(item.item_id?.display_name || "").includes(
                normalizedSearch
            ) ||
            // Description matches
            normalizeString(item.item_id?.description || "").includes(
                normalizedSearch
            )
        ) {
            filteredItems.push(item);
        }

        const notations = item.item_id.notation_links;
        if (Array.isArray(notations)) {
            // If one of the notation matches
            const matchNotations = notations.filter((notation) =>
                normalizeString(
                    notation.notation_id?.display_name || ""
                ).includes(normalizedSearch)
            );

            if (matchNotations.length) {
                filteredItems.push(item);
            }
        }
    });
    return filteredItems;
}

export function containsOnlySpacesOrNewlines(str: string) {
    return /^[\s\n]*$/.test(str);
}
