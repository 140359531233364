import React from "react";
import { useGlobalContext } from "../../../context/Global";
import Layout998 from "../Components/Layout";
import { Root, classes } from "./styles";

export function Collection998(props: any) {
    const { fullMenu, collection } = useGlobalContext();

    return (
        <Layout998 showTitle={false} templateConfigs={{}}>
            <Root style={{ marginTop: "110px", padding: "0 20px 0 20px" }}>
                <div className={classes.welcomeMessage}>
                    {collection?.collection_id?.menu_landing_message}
                </div>
                <div className={classes.welcomeMessage}>
                    {collection?.collection_id?.menu_landing_message_2}
                </div>
                {/*<div className={classes.welcomeMessage}>
                    {collection?.collection_id?.schedule_landing_message}
                </div>*/}
                <div
                    className={classes.logoContainer}
                    style={{
                        display:
                            fullMenu?.presenter_template?.showRestaurantImage &&
                            fullMenu.logo_image
                                ? "flex"
                                : "none"
                    }}
                >
                    <img
                        className={classes.logo}
                        src={fullMenu?.logo_image}
                        alt="This needs to be changed."
                    />
                </div>
            </Root>
        </Layout998>
    );
}
