import { NOTATIONS_LOCAL_STORAGE } from "../const/notations";
import { isLocalStorageAvailable } from "./localStorage";

export class Notations {
    restaurantIdentifier: string;
    constructor(restaurantIdentifier: string) {
        this.restaurantIdentifier = restaurantIdentifier;
    }

    private getName() {
        return `${NOTATIONS_LOCAL_STORAGE}_${this.restaurantIdentifier}`;
    }

    /**
     * This method validates the item in local storage is there and has a valid structure.
     */
    private validate() {
        const showNotations = window.localStorage.getItem(
            this.getName()
        ) as string;

        if (!showNotations) {
            return localStorage.setItem(this.getName(), JSON.stringify(false));
        }

        let showNotationsObj: boolean = false;

        try {
            showNotationsObj = JSON.parse(showNotations);
        } catch (e) {
            console.log(e);
            return localStorage.setItem(this.getName(), JSON.stringify(false));
        }

        if (typeof showNotationsObj !== "boolean") {
            return localStorage.setItem(this.getName(), JSON.stringify(false));
        }
    }

    /**
     * This method create all the logic to get notationStatus.
     */
    get(): boolean {
        if (!isLocalStorageAvailable()) {
            return false;
        }
        this.validate();
        const showNotations = window.localStorage.getItem(
            this.getName()
        ) as string;

        return JSON.parse(showNotations);
    }

    /**
     * This method sets the status of show notations or not.
     * @param {boolean} showNotations - Show notations or not.
     */
    set(showNotations: boolean) {
        if (!isLocalStorageAvailable()) {
            return;
        }
        this.validate();
        localStorage.setItem(this.getName(), JSON.stringify(showNotations));
    }
}
