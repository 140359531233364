/* eslint-disable react-hooks/exhaustive-deps */
import { Grid } from "@mui/material";
import React from "react";
import { Root, classes } from "./styles";
import { useNavigate } from "react-router-dom";
import { useGlobalContext } from "../../../../context/Global";
import NavbarOne from "../Navbar";
import FooterOne from "../Footer";

export default function Layout998(props: {
    templateConfigs: any;
    showTitle: boolean;
    children: React.ReactElement;
}) {
    const { children, showTitle } = props;

    const { fullMenu, collection } = useGlobalContext();
    const navigate = useNavigate();

    return (
        <Root container className={classes.body}>
            <Grid item xs={12}>
                {!showTitle ? (
                    <header>
                        <h1
                            className={classes.title}
                            onClick={() => {
                                navigate(`/${fullMenu?.identifier}`);
                            }}
                        >
                            {fullMenu?.display_name}
                        </h1>
                        <h2
                            className={classes.subtitle}
                            onClick={() => {
                                navigate(
                                    `/${fullMenu?.identifier}/${collection?.collection_id?._id}`
                                );
                            }}
                        >
                            {collection?.collection_id?.display_name}
                        </h2>
                    </header>
                ) : (
                    <header style={{ height: "30px" }}></header>
                )}
                <NavbarOne
                    useAsHeader={showTitle}
                    restaurant={fullMenu}
                ></NavbarOne>
                <main className={classes.main}>{children}</main>
            </Grid>
            <FooterOne></FooterOne>
        </Root>
    );
}
