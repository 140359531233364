import { Container, Grid } from "@mui/material";
import React from "react";
import { useGlobalContext } from "../../../context/Global";
import LayoutOne from "../../../PresenterTemplates/Template1/Components/Layout";
import Section from "../../../PresenterTemplates/Template1/Components/Section";
import { Root, classes } from "./styles";

export default function WebTemplate1() {
    const { fullMenu, menu, showNotations } = useGlobalContext();
    const data = fullMenu?.presenter_template;

    return (
        <LayoutOne
            title=""
            subtitle=""
            showSearch={false}
            showNavbar
            templateConfigs={{}}
            showNotationToggle={false}
            sendTo="web"
            template={fullMenu?.web_template || {}}
        >
            <Container
                maxWidth="md"
                style={{ padding: "0 0 0 0", marginTop: "20px" }}
            >
                <Grid container>
                    <Grid item xs={12}>
                        <>
                            {menu?.menu_id?.section_links?.map(
                                (section, i: number) => (
                                    <React.Fragment
                                        key={
                                            section.section_id.display_name + i
                                        }
                                    >
                                        <Section
                                            launchEvents={false}
                                            showFavoriteIcon={false}
                                            sectionTitle={
                                                section.section_id.display_name
                                            }
                                            sectionDescription={
                                                section.section_id.header_text
                                            }
                                            items={
                                                section.section_id.item_links
                                            }
                                            showNotations={showNotations}
                                            defaultExpanded
                                            alwaysExpanded
                                            id={section.section_id._id}
                                            internalName={
                                                section.section_id
                                                    .internal_name ||
                                                section.section_id.display_name
                                            }
                                            template={
                                                fullMenu?.web_template || {}
                                            }
                                        ></Section>
                                    </React.Fragment>
                                )
                            )}
                        </>
                    </Grid>
                    {menu?.menu_id?.footer_text && (
                        <Grid item xs={12}>
                            <Root template={data}>
                                <p className={classes.footerText}>
                                    {menu?.menu_id?.footer_text}
                                </p>
                            </Root>
                        </Grid>
                    )}
                </Grid>
            </Container>
        </LayoutOne>
    );
}
