import { FAVORITE_LOCAL_STORAGE } from "../const/favorites";
import { isLocalStorageAvailable } from "./localStorage";

interface IFavorites {
    favorites: Array<string>;
}

export class Favorites {
    restaurantIdentifier: string;
    constructor(restaurantIdentifier: string) {
        this.restaurantIdentifier = restaurantIdentifier;
    }

    private getName() {
        return `${FAVORITE_LOCAL_STORAGE}_${this.restaurantIdentifier}`;
    }

    /**
     * This method validates the item in local storage is there and has a valid structure.
     */
    private validateFavorites() {
        const favorites = window.localStorage.getItem(this.getName()) as string;

        let favoritesObj: IFavorites = { favorites: [] };

        if (!favorites) {
            return localStorage.setItem(
                this.getName(),
                JSON.stringify(favoritesObj)
            );
        }

        try {
            favoritesObj = JSON.parse(favorites);
        } catch (e) {
            console.log(e);
            return localStorage.setItem(
                this.getName(),
                JSON.stringify(favoritesObj)
            );
        }

        if (!favoritesObj.favorites || !Array.isArray(favoritesObj.favorites)) {
            return localStorage.setItem(
                this.getName(),
                JSON.stringify(favoritesObj)
            );
        }
    }

    /**
     * This method create all the logic to add favorite items.
     * @param {string} itemId - The ID of the item to set as favorite.
     */
    addFavorite(itemId: string) {
        if (!isLocalStorageAvailable()) {
            return;
        }
        this.validateFavorites();
        const favorites = window.localStorage.getItem(this.getName()) as string;

        const favoritesObj = JSON.parse(favorites);
        favoritesObj.favorites =
            favoritesObj.favorites.indexOf(itemId) === -1
                ? [...favoritesObj.favorites, itemId]
                : favoritesObj.favorites;

        localStorage.setItem(this.getName(), JSON.stringify(favoritesObj));

        return favoritesObj.favorites;
    }

    /**
     * This method create all the logic to remove favorite items.
     * @param {string} itemId - The ID of the favorite item to remove.
     */
    removeFavorite(itemId: string) {
        if (!isLocalStorageAvailable()) {
            return;
        }
        this.validateFavorites();
        const favorites: any = window.localStorage.getItem(this.getName());
        const favoritesObj = JSON.parse(favorites);
        favoritesObj.favorites = favoritesObj.favorites.filter(
            (fav: string) => fav !== itemId
        );
        localStorage.setItem(this.getName(), JSON.stringify(favoritesObj));

        return favoritesObj.favorites;
    }

    /**
     * This method returns the favorites array.
     */
    getFavorites() {
        if (!isLocalStorageAvailable()) {
            return;
        }
        this.validateFavorites();
        const favorites = window.localStorage.getItem(this.getName()) as string;

        const favoritesObj = JSON.parse(favorites);
        return favoritesObj.favorites;
    }

    clearFavorite() {
        if (!isLocalStorageAvailable()) {
            return;
        }
        let favoritesObj: IFavorites = { favorites: [] };
        return window.localStorage.setItem(
            this.getName(),
            JSON.stringify(favoritesObj)
        );
    }
}
