export function isLocalStorageAvailable() {
    try {
        const test = "__test__";
        window.localStorage.setItem(test, test);
        window.localStorage.removeItem(test);
        return true;
    } catch (e) {
        return false;
    }
}
