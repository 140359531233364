import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import packageJSON from "../package.json";

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);
root.render(<App version={packageJSON.version} />);
