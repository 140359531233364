import { styled } from "@mui/material/styles";
import { IPresentTemplate } from "../../../../const";

type Props = {
    template: Partial<IPresentTemplate>;
};
const classes = {
    itemContainer: `itemContainer`,
    itemTitle: `itemTitle`,
    itemDescription: `itemDescription`,
    itemPrice: `itemPrice`,
    loadingContainer: `loadingContainer`,
    sectionDescription: `sectionDescription`,
    notationConatiner: `notationConatiner`,
    notation: `notation`,
    notationSeparator: `notationSeparator`,
    showNotationButtonContainer: `showNotationButtonContainer`,
    itemButton: `itemButton`,
    showNotationButton: `showNotationButton`,
    chekedIcon: `chekedIcon`,
    addToCartIcon: `addToCartIcon`
};

const Root = styled("div")<Props>(({ template }) => ({
    [`&.${classes.itemContainer}`]: {},
    [`& .${classes.itemTitle}`]: {
        fontSize: template?.fonts?.item_name?.size || "1.1rem",
        lineHeight: template?.fonts?.item_name?.line_height || "1.3rem",
        fontWeight: template?.fonts?.item_name?.weight || "600",
        fontFamily: template?.fonts?.item_name?.family || "Inter",
        color: template?.fonts?.item_name?.color || "#000000",
        textAlign: "left",
        margin: "1rem 0 .2rem 0",
        padding: "0 0 0 0",
        whiteSpace: "pre-wrap"
    },
    [`& .${classes.itemDescription}`]: {
        fontFamily: template?.fonts?.item_description?.family || "Inter",
        fontSize: template?.fonts?.item_description?.size || ".9rem",
        lineHeight: template?.fonts?.item_description?.line_height || "1.3rem",
        fontWeight: template?.fonts?.item_description?.weight || "400",
        color: template?.fonts?.item_description?.color || "#000000",
        textAlign: "left",
        margin: "0 0 .3rem 0",
        padding: "0 0 0 0",
        whiteSpace: "pre-wrap"
    },
    [`& .${classes.itemPrice}`]: {
        fontFamily: template?.fonts?.item_price?.family || "Inter",
        fontSize: template?.fonts?.item_price?.size || ".9rem",
        lineHeight: template?.fonts?.item_price?.line_height || "1.1rem",
        fontWeight: template?.fonts?.item_price?.weight || "400",
        color: template?.fonts?.item_price?.color || "#000000",
        textAlign: "left",
        margin: ".3rem 0 0 0",
        padding: "0 0 0 0",
        whiteSpace: "pre-wrap"
    },
    [`& .${classes.loadingContainer}`]: {
        width: "100vw",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    [`& .${classes.sectionDescription}`]: {
        fontFamily: "Inter",
        fontSize: "1rem",
        textAlign: "center",
        fontWeight: "600",
        lineHeight: "1.3",
        fontStyle: "italic",
        whiteSpace: "pre-line",
        marginBottom: "20px"
    },
    [`& .${classes.notationConatiner}`]: {
        display: "flex",
        justifyContent: "flex-start",
        flexWrap: "wrap",
        margin: "0 0 1.3rem 0"
    },
    [`& .${classes.notation}`]: {
        fontFamily: template?.fonts?.notations?.family || "Inter",
        fontWeight: template?.fonts?.notations?.weight || "400",
        color: template?.fonts?.notations?.color || "red",
        fontSize: template?.fonts?.notations?.size || "12px",
        whiteSpace: "nowrap",
        margin: "0 0 0 0",
        display: "inline-block",
        lineHeight: "12.89px"
    },
    [`& .${classes.notationSeparator}`]: {
        height: "4.3px",
        width: "4.3px",
        backgroundColor: template?.colors?.background_2 || "#9E9E9E",
        borderRadius: "50%",
        margin: "0 8px 2px 8px",
        display: "inline-block"
    },
    [`& .${classes.showNotationButtonContainer}`]: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    [`& .${classes.itemButton}`]: {
        padding: "6px 18px",
        border: `${
            template?.item_styles?.item_button?.border_width || "1px"
        } solid ${template?.item_styles?.item_button?.border_color || "black"}`,
        borderRadius:
            template?.item_styles?.item_button?.border_radius || "6px",
        background:
            template?.item_styles?.item_button?.background_color || "white",
        color: template?.item_styles?.item_button?.text_color || "black",
        fontWeight: "600",
        fontSize: "14px",
        lineHeight: "21px",
        cursor: "pointer"
    },
    [`& .${classes.showNotationButton}`]: {},
    [`& .${classes.chekedIcon}`]: {
        width: 30,
        height: 30,
        "& rect": {
            fill: template?.colors?.primary || "#CC5803"
        }
    },
    [`& .${classes.addToCartIcon}`]: {
        width: 30,
        height: 30,
        "& rect": {
            stroke: template?.colors?.primary || "#CC5803"
        },
        "& path": {
            fill: template?.colors?.primary || "#CC5803"
        }
    },
    "& .itemCounterGroup": {
        border: `2px solid ${template?.colors?.primary || "#CC5803"}`,
        borderRadius: "25px",
        padding: "6px 0",
        marginTop: "1rem",
        boxSizing: "border-box"
    },
    "& .itemCounter": {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
        padding: "0 10px",
        boxSizing: "border-box",
        border: "none",
        color: "#737373",
        "&:hover": {
            // backgroundColor: "blue",
            // borderColor: "blue"
        }
    },
    "& .itemCounterText": {
        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                border: "none",
                padding: 0
            },
            "&:hover fieldset": {
                // borderColor: "gray"
            },
            "&.Mui-focused fieldset": {
                // borderColor: "blue"
            },
            "& input": {
                textAlign: "center",
                width: 35,
                padding: 0,
                fontFamily: template?.fonts?.section_links?.family || "Inter",
                fontSize: "15px",
                fontWeight: template?.fonts?.section_links?.weight || "500",
                color: template?.colors?.primary || "#CC5803"
            }
        }
    },
    "& .modifyItemWrapper": {
        marginLeft: 8
    },
    "& .modifyItem": {
        display: "flex",
        alignItems: "center",
        marginBottom: 15,
        "& .itemPrice": {
            fontStyle: "italic",
            margin: "0 0 0 10px",
            flex: "1",
            fontWeight: "500"
        },
        "& .addToCartIcon": {
            cursor: "pointer",
            "& path": {
                fill: "#fff",
                "&:first-of-type": {
                    fill: template?.colors?.primary || "#CC5803",
                    stroke: template?.colors?.primary || "#CC5803"
                }
            },
            "& rect": {
                fill: template?.colors?.primary || "#CC5803",
                stroke: template?.colors?.primary || "#CC5803",
                "& ~ path": {
                    stroke: "#fff !important",
                    fill: "none !important"
                }
            }
        }
    },
    "& .modifyItemPopup": {
        width: "100%",
        position: "fixed",
        bottom: "-100%",
        maxWidth: "500px",
        margin: "0 auto",
        left: 0,
        right: 0,
        zIndex: 9999,
        transition: "all 0.3s",
        "&.openPopup": {
            bottom: "0",
            "&:before": {
                opacity: 1,
                visibility: "visible"
            }
        },
        "&:before": {
            content: "''",
            transition: "all 0.3s",
            opacity: 0,
            visibility: "hidden",
            position: "fixed",
            bottom: 0,
            top: 0,
            maxWidth: "100%",
            left: 0,
            right: 0,
            backgroundColor: "rgba(0,0,0,0.5)"
        },
        "& .modifyItemPopupInner": {
            boxShadow: "0px 0px 16px 0px #00000040",
            backgroundColor: "#fff",
            borderRadius: "20px 20px 0 0",
            padding: "24px 20px",
            boxSizing: "border-box",
            position: "relative",
            zIndex: 1
        },
        "& .closeModifyPopup": {
            width: 38,
            height: 38,
            position: "absolute",
            right: 20,
            top: -55,
            transform: "rotate(45deg)",
            cursor: "pointer",
            "& path": {
                fill: "#222",
                stroke: "#fff",
                "&:first-of-type": {
                    fill: "#fff"
                }
            }
        },
        "& .modifyItemPopupTitle": {
            borderBottom: "1px solid #C0C0C080",
            fontSize: 18,
            fontWeight: 700,
            margin: "0 0 20px",
            paddingBottom: 10,
            color: "#313131"
        },
        "& .modifyPopupItem": {
            marginBottom: 30,
            paddingBottom: 20,
            borderBottom: "1px solid #C0C0C080",
            "& .itemTitle,& .itemDescription, & .itemPrice": {
                color: "#313131"
            }
        },
        "& .modifyPopupMessage": {
            "& .multiline": {
                "& fieldset": {
                    borderRadius: "20px !important"
                },
                "& .MuiInputBase-multiline": {
                    paddingLeft: 20,
                    alignItems: "flex-start",
                    minHeight: 110
                },
                "& label": {
                    fontSize: "15px",
                    color: "#222",
                    fontWeight: 600,
                    fontFamily: "Poppins",
                    backgroundColor: "#fff",
                    padding: "0 4px"
                },
                "& textarea": {
                    fontFamily: "Poppins",
                    color: "#222",
                    opacity: "1 !important",
                    fontSize: 15,
                    lineHeight: 1.5
                },
                "& .MuiInputBase-root": {
                    "&.Mui-focused": {
                        "& fieldset": {
                            borderColor: template?.colors?.primary || "#CC5803"
                        }
                    }
                }
            },
            "& .btnWrapper": {
                display: "flex",
                justifyContent: "space-between",
                marginTop: "30px",
                columnGap: "20px",
                "& .popupBtn": {
                    fontFamily:
                        template?.fonts?.section_links?.family || "Inter",
                    fontSize: template?.fonts?.section_links?.size || "1.2rem",
                    fontWeight: template?.fonts?.section_links?.weight || "500",
                    borderRadius: "10px",
                    padding: "15px 10px",
                    lineHeight: 1.2,
                    flex: "1",
                    boxShadow: "none",
                    backgroundColor: template?.colors?.primary || "#CC5803",
                    color: template?.fonts?.section_links?.color || "#fff",
                    border: `2px solid ${template?.colors?.primary}`,
                    textAlign: "center",
                    textDecoration: "none",
                    transition: "all 0.3s",
                    boxSizing: "border-box",
                    textTransform: "capitalize",
                    "&:hover": {
                        backgroundColor: "transparent",
                        color: template?.colors?.primary || "#CC5803"
                    },
                    "&.clearMessageBtn": {
                        backgroundColor: "transparent",
                        border: "2px solid #888",
                        color: "#888",
                        "&:hover": {
                            backgroundColor: "#888",
                            color: "#fff"
                        }
                    }
                }
            }
        }
    }
}));

export { Root, classes };
