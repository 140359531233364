import moment from "moment-timezone";
import { DEFAULT_TIMEZONE, REDIRECT_FLAG_NAME } from "../const";
import { isLocalStorageAvailable } from "./localStorage";

/**
 * This method tell us if a specific date is between a range.
 */
export function matchSchedule(
    days: number[],
    start: string,
    end: string,
    current: Date,
    timezone: string
) {
    if (typeof start !== "string" || typeof end !== "string") {
        // Handle the case where start or end is not provided or not a string
        return false;
    }

    const [startHour, startMinutes] = start.split(":");
    const [endHour, endMinutes] = end.split(":");

    const mCurrent = moment.tz(current, timezone);

    const mStartDate = moment.tz(new Date(), timezone);
    const mEndDate = moment.tz(new Date(), timezone);

    mStartDate.set("hour", parseInt(startHour));
    mStartDate.set("minute", parseInt(startMinutes));

    mEndDate.set("hour", parseInt(endHour));
    mEndDate.set("minute", parseInt(endMinutes));

    return (
        mCurrent.isBetween(mStartDate, mEndDate) &&
        days.includes(current.getDay())
    );
}

export function setRedirectFlag(identifier: string) {
    window.sessionStorage.setItem(
        `${REDIRECT_FLAG_NAME}_${identifier}`,
        JSON.stringify(true)
    );
}

export function getRedirectFlag(identifier: string) {
    if (!isLocalStorageAvailable()) return false;
    const flag = window.sessionStorage.getItem(
        `${REDIRECT_FLAG_NAME}_${identifier}`
    );
    if (!flag) return false;
    return Boolean(JSON.parse(flag || ""));
}

/**
 * This method validates the timezone given in the parameter, if it is invalid, it will return one as default.
 * @param {string} current - The given timezone to verify.
 */
export function getValidTimezone(current: string): string {
    const timezones = Intl.supportedValuesOf("timeZone");
    return timezones.includes(current) ? current : DEFAULT_TIMEZONE;
}
