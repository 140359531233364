// Adding an item to Session Storage
export const addItemToSessionStorage = (key: any, value: any) => {
    const cookieEnabled = navigator.cookieEnabled;
    if (cookieEnabled) {
        sessionStorage.setItem(key, JSON.stringify(value));
    }
};

// Getting an item from Session Storage
export const getItemFromSessionStorage = (key: any) => {
    const cookieEnabled = navigator.cookieEnabled;
    if (cookieEnabled) {
        const storedItem = sessionStorage.getItem(key);
        return storedItem ? JSON.parse(storedItem) : null;
    }
};

// Removing an item from Session Storage
export const removeItemFromSessionStorage = (key: any) => {
    const cookieEnabled = navigator.cookieEnabled;
    if (cookieEnabled) {
        sessionStorage.removeItem(key);
    }
};
