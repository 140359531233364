import { Grid } from "@mui/material";
import { styled } from "@mui/material/styles";

const classes = {
    body: `body`,
    menu: `menu`,
    title: `title`,
    subtitle: `subtitle`,
    main: `main`
};

const Root = styled(Grid)(() => ({
    [`&.${classes.body}`]: {},
    [`& .${classes.menu}`]: {},
    [`& .${classes.title}`]: {
        fontFamily: "Inter",
        fontWeight: "400",
        fontSize: "1.5rem",
        textAlign: "center",
        "&:hover": {
            cursor: "pointer"
        }
    },
    [`& .${classes.subtitle}`]: {
        textAlign: "center",
        fontFamily: "Inter",
        fontSize: "1.3rem",
        fontWeight: "400",
        marginTop: "-11px",
        marginBottom: "5px",
        "&:hover": {
            cursor: "pointer"
        }
    },
    [`& .${classes.main}`]: {
        marginTop: "60px"
    }
}));

export { Root, classes };
