import { CircularProgress } from "@mui/material";
import React from "react";
import { Root, classes } from "./styles";

export default function LoadingScreen() {
    return (
        <Root className={classes.container}>
            <CircularProgress style={{ color: "black" }}></CircularProgress>
        </Root>
    );
}
