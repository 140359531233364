import { styled } from "@mui/material/styles";
import { IPresentTemplate } from "../../../const";

type Props = {
    template?: IPresentTemplate;
};

const classes = {
    itemContainer: `itemContainer`,
    itemTitle: `itemTitle`,
    itemDescription: `itemDescription`,
    itemPrice: `itemPrice`,
    loadingContainer: `loadingContainer`,
    sectionDescription: `sectionDescription`,
    notationConatiner: `notationConatiner`,
    notation: `notation`,
    showNotationButtonContainer: `showNotationButtonContainer`,
    showNotationButton: `showNotationButton`,
    menuHeader: `menuHeader`,
    footerText: `footerText`,
    logoContainer: `logoContainer`,
    logo: `logo`
};

const RootP = styled("p")<Props>(({ template }) => ({
    [`&.${classes.menuHeader}`]: {
        fontFamily: template?.fonts?.menu_header?.family || "Inter",
        fontSize: template?.fonts?.menu_header?.size || "15px",
        lineHeight: template?.fonts?.menu_header?.line_height || "1",
        color: template?.fonts?.menu_header?.color || "#222222",
        fontWeight: template?.fonts?.menu_header?.weight || "400",
        marginLeft: "15px",
        marginTop: "0",
        whiteSpace: "pre-wrap"
    },
    [`&.${classes.footerText}`]: {
        fontFamily: template?.fonts?.menu_footer?.family || "Inter",
        fontSize: template?.fonts?.menu_footer?.size || "12px",
        lineHeight: template?.fonts?.menu_footer?.line_height || "16px",
        color: template?.fonts?.menu_footer?.color || "#222222",
        fontWeight: template?.fonts?.menu_footer?.weight || "400",
        textAlign: "center",
        whiteSpace: "pre-wrap",
        margin: "3rem 25px 0 25px"
    }
}));
const Root = styled("div")<Props>(({ template }) => ({
    [`&.${classes.itemContainer}`]: {
        marginBottom: "25px"
    },
    [`& .${classes.itemTitle}`]: {
        whiteSpace: "pre-line",
        padding: "0 0 .1rem 0",
        fontSize: "1rem",
        fontFamily: "Inter",
        textAlign: "left",
        margin: "5px 0 -13px 0"
    },
    [`& .${classes.itemDescription}`]: {
        fontFamily: "Inter",
        padding: "0 0 0 0",
        fontSize: ".9rem",
        textAlign: "left",
        whiteSpace: "pre-wrap"
    },
    [`& .${classes.itemPrice}`]: {
        fontFamily: "Inter",
        padding: "0 0 0 0",
        fontSize: ".9rem",
        textAlign: "left",
        marginTop: "-10px"
    },
    [`& .${classes.loadingContainer}`]: {
        width: "100vw",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    [`& .${classes.sectionDescription}`]: {
        fontFamily: "Inter",
        fontSize: "1rem",
        textAlign: "center",
        fontWeight: "600",
        lineHeight: "1.3",
        fontStyle: "italic",
        whiteSpace: "pre-line",
        marginBottom: "20px"
    },
    [`& .${classes.notationConatiner}`]: {
        display: "flex",
        justifyContent: "center",
        margin: "18px 0 -10px 0"
    },
    [`& .${classes.notation}`]: {
        display: "inline-block",
        border: "1px solid black",
        borderRadius: "5px",
        fontSize: ".8rem",
        fontWeight: "300",
        fontFamily: "Inter",
        padding: ".1rem .5rem .1rem .5rem",
        margin: "3px 3px 3px 3px"
    },
    [`& .${classes.showNotationButtonContainer}`]: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    [`& .${classes.showNotationButton}`]: {},
    [`& .${classes.menuHeader}`]: {
        fontFamily: template?.fonts?.menu_header?.family || "Inter",
        fontSize: template?.fonts?.menu_header?.size || "15px",
        lineHeight: template?.fonts?.menu_header?.line_height || "1",
        color: template?.fonts?.menu_header?.color || "#222222",
        fontWeight: template?.fonts?.menu_header?.weight || "400",
        marginLeft: "15px",
        marginTop: "0"
    },
    [`& .${classes.footerText}`]: {
        fontFamily: template?.fonts?.menu_footer?.family || "Inter",
        fontSize: template?.fonts?.menu_footer?.size || "12px",
        lineHeight: template?.fonts?.menu_footer?.line_height || "16px",
        color: template?.fonts?.menu_footer?.color || "#222222",
        fontWeight: template?.fonts?.menu_footer?.weight || "400",
        textAlign: "center",
        whiteSpace: "pre-wrap",
        margin: "3rem 25px 0 25px"
    },
    [`& .${classes.logoContainer}`]: {
        display: "flex !important",
        justifyContent: "center",
        marginTop: "3rem"
    },
    [`& .${classes.logo}`]: { maxWidth: "225px", maxHeight: "180px" }
}));

export { Root, classes, RootP };
