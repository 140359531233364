import { styled } from "@mui/material/styles";
import { IPresentTemplate } from "../../../../const";
import { Grid } from "@mui/material";

type Props = {
    template?: IPresentTemplate;
};

const classes = {
    searchBarContainer: `searchBarContainer`,
    searchBar: `searchBar`,
    fabAdornamentQuit: `fabAdornamentQuit`,
    fabAdornament: `fabAdornament`,
    badge: `badge`,
    main: `main`
};

const Root = styled(Grid)<Props>(({ template }) => ({
    [`&.${classes.searchBarContainer}`]: {
        display: "flex",
        alignItems: "center",
        flexWrap: "nowrap",
        justifyContent: "space-between",
        marginBottom: "20px"
    },
    [`& .${classes.searchBar}`]: {
        width: "93%",
        "& .MuiOutlinedInput-root": {
            color: template?.fonts?.search_text?.color,
            name: template?.fonts?.search_text?.name,
            fontFamily: template?.fonts?.search_text?.family,
            fontWeight: template?.fonts?.search_text?.weight,
            lineHeight: template?.fonts?.search_text?.line_height,
            size: template?.fonts?.search_text?.size,
            "& fieldset": {
                borderRadius: `10px`
            },
            "&.Mui-focused fieldset": {
                borderColor: template?.colors?.primary
            }
        },
        "& label.Mui-focused": {
            color: template?.colors?.primary
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: template?.colors?.secondary || "#CC5803"
        }
    },
    [`& .${classes.fabAdornamentQuit}`]: {
        backgroundColor: "transparent",
        color: "#fff !important",
        transform: "scale(.7)"
    },
    [`& .${classes.fabAdornament}`]: {
        backgroundColor: `${template?.colors?.primary || "#CC5803"} !important`,
        color: "#fff !important",
        transform: "scale(.7)"
    },
    [`& .${classes.badge}`]: {
        backgroundColor: template?.colors?.primary || "#CC5803",
        color: "#ffff",
        fontWeight: "600 !important"
    },
    [`& .${classes.main}`]: {}
}));

export { Root, classes };
