/* eslint-disable react-hooks/exhaustive-deps */
import { Container, Grid } from "@mui/material";
import React from "react";
import { Root, classes } from "./styles";
import FooterOne from "../Footer";
import { useGlobalContext } from "../../../../context/Global";
import "./index.css";
import { useNavigate } from "react-router-dom";
import Navbar1 from "../Navbar";
import SearchBar from "../Search";
import ShowNotationToggle from "../ShowNotationsToggle";
import { Notations } from "../../../../utils/notations";
import { useTrackContext } from "../../../../context/Tracking";
import { IPresentTemplate } from "../../../../const";
import ShowImage from "../Logo";
import LanguageSelector from "../../../../SharedComponents/LanguageSelector";
import _ from "lodash";

export default function LayoutOne(props: {
    templateConfigs: any;
    title?: string;
    subtitle?: string;
    headerImage?: string;
    showSearch: boolean;
    showNavbar: boolean;
    showNotationToggle: boolean;
    sendTo?: "web" | "presenter";
    children: React.ReactElement;
    template: Partial<IPresentTemplate>;
}) {
    const { children, template, headerImage } = props;

    const { fullMenu, collection, showNotations, setShowNotations, menu } =
        useGlobalContext();
    const { newEvent } = useTrackContext();
    const navigate = useNavigate();

    const show_service_period_name =
        typeof template?.controls?.show_service_period_name === "boolean"
            ? template?.controls?.show_service_period_name
            : true;

    React.useEffect(() => {
        if (fullMenu) {
            const show = new Notations(`${fullMenu.identifier}`);
            setShowNotations(show.get());
        }
    }, []);

    /**
     * This method handles the state to show notations or not.
     */
    function onShowNotations() {
        const show = new Notations(`${fullMenu?.identifier}`);
        const notationState = !showNotations;
        show.set(notationState);
        setShowNotations(notationState);
        window.scrollTo(0, 0);
        if (notationState) {
            return newEvent(
                "show-notations",
                {
                    template: "1",
                    name: menu?.menu_id?.display_name || "Not defined"
                },
                fullMenu?.identifier || ""
            );
        }
        newEvent(
            "hide-notations",
            {
                template: "1",
                name: menu?.menu_id?.display_name || "Not defined"
            },
            fullMenu?.identifier || ""
        );
    }
    const urlSearchParams = new URLSearchParams(window.location.search);
    const isOrderOn = urlSearchParams.get("order") === "on";
    function onTitleClick() {
        if (!_.get(fullMenu, "all_day_menu", true)) {
            navigate(`/${fullMenu?.identifier}${isOrderOn ? "?order=on" : ""}`);
        } else {
            fullMenu?.collection_links?.length &&
                fullMenu?.collection_links?.length > 1 &&
                navigate(
                    `/${fullMenu?.identifier}${isOrderOn ? "?order=on" : ""}`
                );
        }
    }

    return (
        <Root template={template} container className={classes.body}>
            <Grid item xs={12}>
                <LanguageSelector />
                <div className={classes.header}>
                    {headerImage ? (
                        <>
                            <ShowImage
                                imageURL={
                                    headerImage || fullMenu?.logo_image || ""
                                }
                                altName={fullMenu?.display_name || "restaurant"}
                                onClick={onTitleClick}
                            ></ShowImage>
                        </>
                    ) : template?.controls?.show_logo === "top" ? (
                        <>
                            <ShowImage
                                imageURL={fullMenu?.logo_image || ""}
                                altName={fullMenu?.display_name || "restaurant"}
                                onClick={onTitleClick}
                            ></ShowImage>
                            {props.subtitle && show_service_period_name && (
                                <h2
                                    className={classes.subtitle}
                                    onClick={() =>
                                        template?.controls?.show_splash_page &&
                                        navigate(
                                            `/${fullMenu?.identifier}/${collection?.collection_id?._id}`
                                        )
                                    }
                                    style={{
                                        marginTop: "10px"
                                    }}
                                >
                                    {props.subtitle}
                                </h2>
                            )}
                        </>
                    ) : (
                        <>
                            {/* The title */}
                            {props.title && (
                                <h1
                                    className={classes.title}
                                    onClick={onTitleClick}
                                >
                                    {props.title}
                                </h1>
                            )}
                            {/* The subtitle */}
                            {props.subtitle && show_service_period_name && (
                                <h2
                                    className={classes.subtitle}
                                    onClick={() =>
                                        template?.controls?.show_splash_page &&
                                        navigate(
                                            `/${fullMenu?.identifier}/${collection?.collection_id?._id}`
                                        )
                                    }
                                >
                                    {props.subtitle}
                                </h2>
                            )}
                        </>
                    )}
                </div>
            </Grid>
            {/* The search bar */ props.showSearch && <SearchBar></SearchBar>}
            {/* The navbar */}
            {props.showNavbar && (
                <Navbar1 template={template} sendTo={props.sendTo}></Navbar1>
            )}
            <Grid item xs={12}>
                <main className={classes.main}>
                    {props.showNotationToggle && (
                        <Container maxWidth="md" style={{ padding: "0 0 0 0" }}>
                            <ShowNotationToggle
                                showNotations={showNotations}
                                handleToggle={onShowNotations}
                            ></ShowNotationToggle>
                        </Container>
                    )}
                    {children}
                </main>
            </Grid>
            <FooterOne template={template}></FooterOne>
        </Root>
    );
}
