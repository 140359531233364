import { useGlobalContext } from "../../../../context/Global";
import { Root, classes } from "./styles";
// eslint-disable-next-line import/no-webpack-loader-syntax
import EmtyCart from "-!svg-react-loader!../../../../assets/icons/emptyCart.svg";
import { useIntl } from "react-intl";

export default function EmptyCart(props: { isCartPage: boolean }) {
    const { isCartPage } = props;
    const { fullMenu } = useGlobalContext();
    const data = fullMenu?.presenter_template;

    const intl = useIntl();

    return (
        <Root template={data} className={`${classes.container} no-favorites`}>
            <EmtyCart className={classes.searchIcon}></EmtyCart>
            <h5 className={classes.title}>
                {isCartPage
                    ? intl.formatMessage({
                          id: "cart.empty_title"
                      })
                    : intl.formatMessage({
                          id: "empty_search"
                      })}
            </h5>
            <p className={classes.content}>
                {isCartPage
                    ? intl.formatMessage({
                          id: "cart.empty_text"
                      })
                    : intl.formatMessage({
                          id: "empty_search_suggest"
                      })}
            </p>
        </Root>
    );
}
