import { Tooltip } from "@mui/material";
import { styled } from "@mui/material/styles";

type Props = {
    color: string;
    background?: string;
};
const classes = {
    toolbar: `toolbar`,
    searchIcon: `searchIcon`
};
const Root = styled(Tooltip)<Props>(({ color, background }) => ({
    [`&.${classes.toolbar}`]: {
        background: (background || "#FFFFFF") + " !important",
        color,
        boxShadow: "0px 10px 10px rgba(0, 0, 0, 0.5 !important)",
        margin: "0px 5px 0px 5px !important",
        transition: "0.3s !important",
        "&:hover": {
            transform: "scale(1.1)"
        }
    },
    [`& .${classes.searchIcon}`]: {
        transform: "scale(1.5)",
        "& path, circle": {
            fill: color || "black"
        }
    }
}));

export { Root, classes };
