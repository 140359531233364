/* eslint-disable react-hooks/exhaustive-deps */
import { Container, Grid } from "@mui/material";
import React from "react";
import { useParams } from "react-router-dom";
import { useGlobalContext } from "../../../context/Global";
import { useTrackContext } from "../../../context/Tracking";
import LayoutOne from "../Components/Layout";
import Section from "../Components/Section";
import {
    containsOnlySpacesOrNewlines,
    filterItems
} from "../../../utils/filter";
import NoSearchResults from "../Components/NoSearchResult";
import { classes, RootP } from "./styles";
import BackToTop from "../../../SharedComponents/BackToTop";
import { IItem } from "../../../const";
import ShowImage from "../Components/Logo";
import { useIntl } from "react-intl";

export default function Menu1(props: any) {
    const { fullMenu, collection, menu, search, showNotations } =
        useGlobalContext();
    const { newEvent } = useTrackContext();
    const { restaurant: restaurantIdentifier } = useParams();
    const [filteredItems, setFilteredItems] = React.useState<
        Array<{
            item_id: IItem;
        }>
    >([]);

    const data = fullMenu?.presenter_template;

    const intl = useIntl();

    // Effect to handle the search in the entire collection
    React.useEffect(() => {
        //setFiteredItems(filterItems(getFavorites(), search || ""));
        let items: Array<{
            item_id: IItem;
        }> = [];

        // Filter over collections - menus - sections
        collection?.collection_id?.menu_links.forEach((_menu) => {
            _menu?.menu_id?.section_links?.forEach((section) => {
                // Filter the items and add them to the items array, we wont store repeated items
                items = [
                    ...items,
                    ...filterItems(
                        section.section_id.item_links,
                        search || ""
                    ).filter(
                        (it) =>
                            !items.find((i) => i.item_id._id === it.item_id._id)
                    )
                ];
            });
        });

        function eventSearch() {
            newEvent(
                "search-performed",
                {
                    name: search || "",
                    template: "1",
                    items_returned: items.length
                },
                restaurantIdentifier || ""
            );
        }

        if (search?.length) {
            eventSearch();
        }
        setFilteredItems(items);
    }, [search]);

    // Track the menu view event
    React.useEffect(() => {
        if (typeof menu?.menu_id !== "undefined" && restaurantIdentifier) {
            // track collection view if we're on the menu-view URL
            if (
                typeof collection?.collection_id !== "undefined" &&
                restaurantIdentifier
            ) {
                newEvent(
                    "collection-view",
                    {
                        _id: collection?.collection_id?._id,
                        name:
                            collection?.collection_id?.internal_name ||
                            collection?.collection_id?.display_name,
                        template: "1"
                    },
                    restaurantIdentifier
                );
            }

            // track menu view
            newEvent(
                "menu-view",
                {
                    _id: menu?.menu_id?._id,
                    name:
                        menu?.menu_id?.internal_name ||
                        menu?.menu_id?.display_name,
                    template: "1",
                    // If the menu has deactivated the notations. just send false
                    notations: menu.menu_id.show_notations
                        ? showNotations
                        : false,
                    // If the section is collapsable, we use the flag accordion_section_starts_open, otherwise is always open
                    expanded: fullMenu?.presenter_template.controls
                        ?.accordion_section
                        ? Boolean(
                              fullMenu?.presenter_template.controls
                                  ?.accordion_section_starts_open
                          )
                        : true
                },
                restaurantIdentifier
            );
        } else {
            return;
        }
    }, [newEvent, menu, collection]);

    // This effect takes care of the background color of the body
    const bodyWrapper = document.querySelector(".body-wrapper") as HTMLElement;
    React.useEffect(() => {
        const backgroundColor =
            fullMenu?.presenter_template.colors?.background_1 || "#fff";
        const className = "bg-" + backgroundColor;
        //  add class to body element
        document.body.classList.add(className);

        //  set style on body element
        bodyWrapper.style.backgroundColor = backgroundColor;

        return () => {
            //  optionally remove styles when component unmounts
            bodyWrapper.style.backgroundColor = "";

            document.body.classList.remove(className);
        };
    }, []);

    function renderBottomLogo() {
        if (!fullMenu?.logo_image) {
            return null;
        }
        if (
            fullMenu.presenter_template.controls?.show_logo === "bottom" ||
            menu?.menu_id?.show_logo
        ) {
            return (
                <Grid item xs={12}>
                    <ShowImage
                        style={{ marginTop: "3rem" }}
                        imageURL={fullMenu?.logo_image || ""}
                        altName={fullMenu?.display_name || "restaurant"}
                    ></ShowImage>
                </Grid>
            );
        }
        return null;
    }

    const isBlank = containsOnlySpacesOrNewlines(menu?.menu_id?.header_text);

    return (
        <LayoutOne
            title={fullMenu?.display_name}
            subtitle={collection?.collection_id?.display_name}
            showSearch
            showNavbar
            showNotationToggle
            templateConfigs={{}}
            template={fullMenu?.presenter_template || {}}
        >
            <Container maxWidth="md" style={{ padding: "0 0 0 0" }}>
                <Grid container>
                    <Grid item xs={12}>
                        {search ? (
                            <>
                                <Section
                                    sectionTitle={intl.formatMessage({
                                        id: "items.search_result"
                                    })}
                                    sectionDescription=""
                                    items={filteredItems as any}
                                    showNotations={showNotations}
                                    defaultExpanded
                                    alwaysExpanded
                                    id="section-for-search"
                                    internalName={intl.formatMessage({
                                        id: "items.search_result"
                                    })}
                                    template={
                                        fullMenu?.presenter_template || {}
                                    }
                                ></Section>
                                {!filteredItems.length && (
                                    <NoSearchResults
                                        isFavoritePage={false}
                                    ></NoSearchResults>
                                )}
                            </>
                        ) : (
                            <>
                                {menu?.menu_id?.header_text && !isBlank && (
                                    <RootP
                                        template={data}
                                        className={classes.menuHeader}
                                    >
                                        {menu?.menu_id?.header_text}
                                    </RootP>
                                )}
                                {menu?.menu_id?.section_links?.map(
                                    (section, i: number) => (
                                        <React.Fragment
                                            key={
                                                section.section_id
                                                    .display_name + i
                                            }
                                        >
                                            <Section
                                                sectionTitle={
                                                    section.section_id
                                                        .display_name
                                                }
                                                sectionDescription={
                                                    section.section_id
                                                        .header_text
                                                }
                                                sectionAlwaysOpen={
                                                    section.section_id
                                                        .section_always_open
                                                }
                                                items={
                                                    section.section_id
                                                        .item_links
                                                }
                                                showNotations={showNotations}
                                                defaultExpanded={
                                                    fullMenu?.presenter_template
                                                        .controls
                                                        ?.accordion_section
                                                        ? Boolean(
                                                              fullMenu
                                                                  .presenter_template
                                                                  .controls
                                                                  ?.accordion_section_starts_open
                                                          )
                                                        : false
                                                }
                                                alwaysExpanded={
                                                    !fullMenu
                                                        ?.presenter_template
                                                        .controls
                                                        ?.accordion_section
                                                }
                                                id={section.section_id._id}
                                                internalName={
                                                    section.section_id
                                                        .internal_name ||
                                                    section.section_id
                                                        .display_name
                                                }
                                                template={
                                                    fullMenu?.presenter_template ||
                                                    {}
                                                }
                                            ></Section>
                                        </React.Fragment>
                                    )
                                )}
                            </>
                        )}
                    </Grid>
                    {renderBottomLogo()}
                    {menu?.menu_id?.footer_text && (
                        <Grid item xs={12}>
                            <Container>
                                <RootP
                                    template={data}
                                    className={classes.footerText}
                                >
                                    {menu?.menu_id?.footer_text}
                                </RootP>
                            </Container>
                        </Grid>
                    )}
                </Grid>
                {fullMenu?.presenter_template.controls?.show_back_to_top && (
                    <BackToTop
                        backgroundColor={
                            fullMenu?.presenter_template.colors?.tertiary ||
                            "grey"
                        }
                        color={
                            fullMenu?.presenter_template.colors?.primary ||
                            "black"
                        }
                    ></BackToTop>
                )}
            </Container>
        </LayoutOne>
    );
}
