import { styled } from "@mui/material/styles";

const classes = {
    secondaryMessageContainer: `secondaryMessageContainer`,
    secondaryMessage: `secondaryMessage`,
    description: `description`
};

const Root = styled("div")(() => ({
    [`& .${classes.secondaryMessageContainer}`]: {
        paddingTop: "50px"
    },
    [`& .${classes.secondaryMessage}`]: {
        textAlign: "center",
        fontFamily: "Inter",
        fontSize: "1.2rem",
        fontWeight: "400",
        cursor: "pointer",
        textDecoration: "underline"
    },
    [`& .${classes.description}`]: {
        fontFamily: "Inter",
        textAlign: "center",
        fontSize: ".9rem",
        fontStyle: "italic",
        marginTop: "-15px"
    }
}));

export { Root, classes };
