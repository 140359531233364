import { AppBar } from "@mui/material";
import { styled } from "@mui/material/styles";

const classes = {
    appBar: `appBar`,
    menuItem: `menuItem`,
    navbarTitle: `navbarTitle`
};

const Root = styled(AppBar)(() => ({
    [`&.${classes.appBar}`]: {
        height: "53px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    [`& .${classes.menuItem}`]: {
        fontFamily: "Inter",
        fontWeight: "500",
        fontSize: "1.2rem",
        textTransform: "unset",
        padding: "0 20px 0 20px"
    },
    [`& .${classes.navbarTitle}`]: {
        fontFamily: "Inter",
        fontSize: "1.5rem",
        "&:hover": {
            cursor: "pointer"
        }
    }
}));

export { Root, classes };
