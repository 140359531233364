/* eslint-disable react-hooks/exhaustive-deps */
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { Box, InputAdornment, TextField } from "@mui/material";
import { Container } from "@mui/system";
import React, { KeyboardEvent } from "react";
import { useLocation } from "react-router-dom";
import { useGlobalContext } from "../../../../context/Global";
import { Root, classes } from "./styles";

export default function SearchBar(props: any) {
    const { fullMenu, setSearch, setFilteredItems, search } =
        useGlobalContext();
    const data = fullMenu?.presenter_template;
    const [currentSearch, setCurrentSearch] = React.useState<string>("");
    const location = useLocation();

    React.useEffect(() => {
        if (!currentSearch) {
            setSearch && setSearch("");
            setFilteredItems && setFilteredItems([]);
        }
    }, [currentSearch]);

    React.useEffect(() => {
        if (!search) {
            setCurrentSearch("");
        }
    }, [search]);

    React.useEffect(() => {
        setSearch && setSearch("");
        setFilteredItems && setFilteredItems([]);
        setCurrentSearch("");
    }, [location]);

    function filter() {
        setSearch && setSearch(currentSearch);
    }

    function handleKeypress(e: KeyboardEvent<HTMLDivElement>) {
        if (e.key === "Enter") {
            filter();
        }
    }

    return (
        <Root template={data} item xs={12}>
            <Container
                maxWidth="sm"
                sx={{
                    "&.MuiContainer-root": {
                        p: 0
                    }
                }}
            >
                <TextField
                    size="small"
                    InputProps={{
                        endAdornment: (
                            <InputAdornment
                                position="end"
                                style={{
                                    marginRight: "-12px"
                                }}
                            >
                                {
                                    <Box
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center"
                                        }}
                                        onClick={() => {
                                            setFilteredItems &&
                                                setFilteredItems([]);
                                            setCurrentSearch("");
                                            setSearch && setSearch("");
                                        }}
                                    >
                                        <CloseOutlinedIcon
                                            sx={{
                                                width: "25px",
                                                height: "25px",
                                                cursor: "pointer",
                                                p: 1,
                                                color: fullMenu
                                                    ?.presenter_template.fonts
                                                    ?.menu_links?.color
                                            }}
                                        ></CloseOutlinedIcon>
                                    </Box>
                                }
                            </InputAdornment>
                        )
                    }}
                    placeholder="Enter Search Text"
                    className={classes.searchBar}
                    value={currentSearch}
                    onChange={(e) => setCurrentSearch(e.target.value)}
                    onKeyPress={handleKeypress}
                ></TextField>
            </Container>
        </Root>
    );
}
