/* eslint-disable react-hooks/exhaustive-deps */
import {
    Box,
    Card,
    CardMedia,
    Container,
    Grid,
    Skeleton,
    Typography
} from "@mui/material";
import React from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useGlobalContext } from "../../../context/Global";
import { useTrackContext } from "../../../context/Tracking";
import LayoutThree from "../Components/Layout";
import Section from "../Components/Section";
import { filterItems } from "../../../utils/filter";
import NoSearchResults from "../Components/NoSearchResult";
import BackToTop from "../../../SharedComponents/BackToTop";
import { IItem, IMenu } from "../../../const";
import { useIntl } from "react-intl";

export default function Menu2(props: any) {
    const { fullMenu, collection, menu, search } = useGlobalContext();
    const [searchParams] = useSearchParams();
    const { newEvent } = useTrackContext();
    const { restaurant: restaurantIdentifier } = useParams();
    const [showNotations, setShowNotations] = React.useState<boolean>(false);
    const [filteredItems, setFilteredItems] = React.useState<
        Array<{
            item_id: IItem;
        }>
    >([]);

    const intl = useIntl();

    React.useEffect(() => {
        //setFiteredItems(filterItems(getFavorites(), search || ""));
        let items: Array<{
            item_id: IItem;
        }> = [];

        // Filter over collections - menus - sections
        collection?.collection_id?.menu_links.forEach((_menu) => {
            _menu?.menu_id?.section_links?.forEach((section) => {
                items = [
                    ...items,
                    ...filterItems(section.section_id.item_links, search || "")
                ];
            });
        });

        function eventSearch() {
            newEvent(
                "search-performed",
                {
                    name: search || "",
                    template: "2",
                    items_returned: items.length
                },
                restaurantIdentifier || ""
            );
        }

        if (search?.length) {
            eventSearch();
        }
        setFilteredItems(items);
    }, [search]);

    React.useEffect(() => {
        const notationState =
            searchParams.get("notations") === "true" ? true : false;
        setShowNotations(notationState);
    }, [searchParams]);

    React.useEffect(() => {
        if (typeof menu?.menu_id !== "undefined" && restaurantIdentifier) {
            // Track collection-view
            if (
                typeof collection?.collection_id !== "undefined" &&
                restaurantIdentifier
            ) {
                newEvent(
                    "collection-view",
                    {
                        _id: collection.collection_id._id,
                        name: collection.collection_id.display_name,
                        template: "2"
                    },
                    restaurantIdentifier
                );
            }

            // Track menu-view
            newEvent(
                "menu-view",
                {
                    _id: menu?.menu_id?._id,
                    name:
                        menu?.menu_id?.internal_name ||
                        menu?.menu_id?.display_name,
                    template: "2",
                    notations: showNotations,
                    expanded: true
                },
                restaurantIdentifier
            );
        } else {
            return;
        }
    }, [newEvent, menu, collection]);

    const refs = menu?.menu_id?.section_links.reduce((acc: any, value: any) => {
        acc[value.section_id._id] = React.createRef();
        return acc;
    }, {});

    function launchExpandEvent(item: IMenu) {
        newEvent(
            "section-expand",
            {
                _id: item._id,
                name: item.internal_name,
                template: "2"
            },
            restaurantIdentifier || ""
        );
    }

    const scrollToItem = (id: string) => {
        const trackItem: any = menu?.menu_id?.section_links.filter(
            (link) => link.section_id._id === id
        )[0];

        launchExpandEvent(trackItem.section_id);

        refs[id].current.scrollIntoView({
            behavior: "smooth",
            block: "start",
            inline: "nearest"
        });
    };

    const headerImage = fullMenu?.presenter_template.images?.header_image;
    const featuredImage = fullMenu?.presenter_template.images?.featured_image;
    const primaryBackgroundColor =
        fullMenu?.presenter_template?.colors?.primary;

    return (
        <LayoutThree
            title={headerImage ? headerImage : fullMenu?.display_name}
            subtitle={collection?.collection_id?.display_name}
            showSearch={true}
            showNavbar={false}
            templateConfigs={{}}
        >
            <Container
                maxWidth="md"
                sx={{
                    padding: 0,
                    backgroundImage: {
                        lg: `url(${featuredImage})`,
                        md: `url(${featuredImage})`,
                        sm: `url(${featuredImage})`,
                        xl: `url(${featuredImage})`
                    },
                    p: {
                        lg: 0,
                        md: 0,
                        xl: 0,
                        sm: 0
                    },
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    backgroundAttachment: "fixed"
                }}
            >
                <Grid container sx={{ p: 0 }}>
                    {featuredImage && (
                        <Box
                            sx={{
                                height: "375px",
                                width: "100%"
                            }}
                        >
                            {!featuredImage && (
                                <Skeleton
                                    variant="rectangular"
                                    width={"100%"}
                                    height={"375px"}
                                />
                            )}
                        </Box>
                    )}

                    <Grid item xs={12}>
                        {search ? (
                            <>
                                <Section
                                    sx={{
                                        background: `${
                                            primaryBackgroundColor
                                                ? primaryBackgroundColor
                                                : `#ffffff`
                                        }`,

                                        color: fullMenu?.presenter_template
                                            .fonts?.section_links?.color,
                                        m: 0
                                    }}
                                    sectionTitle={intl.formatMessage({
                                        id: "items.search_result"
                                    })}
                                    sectionDescription=""
                                    items={filteredItems as any}
                                    showNotations={showNotations}
                                    defaultExpanded
                                    alwaysExpanded
                                    id="section-for-search"
                                    internalName={intl.formatMessage({
                                        id: "items.search_result"
                                    })}
                                ></Section>
                                {
                                    <NoSearchResults
                                        filteredItems={!filteredItems.length}
                                        isFavoritePage={false}
                                    ></NoSearchResults>
                                }
                            </>
                        ) : (
                            <>
                                <Grid
                                    container
                                    sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "space-around",
                                        background: `${
                                            primaryBackgroundColor
                                                ? primaryBackgroundColor
                                                : `#ffffff`
                                        }`
                                    }}
                                >
                                    {menu?.menu_id?.section_links.map(
                                        (link: any) => {
                                            return (
                                                <Grid
                                                    item
                                                    key={link.section_id._id}
                                                >
                                                    <Typography
                                                        noWrap
                                                        key={
                                                            link.section_id._id
                                                        }
                                                        onClick={() =>
                                                            scrollToItem(
                                                                link.section_id
                                                                    ._id
                                                            )
                                                        }
                                                        sx={{
                                                            mt: "25px",
                                                            mb: "40px",
                                                            cursor: "pointer",
                                                            textDecorationLine:
                                                                "underline",
                                                            fontFamily:
                                                                fullMenu
                                                                    ?.presenter_template
                                                                    ?.fonts
                                                                    ?.section_links
                                                                    ?.family,
                                                            fontWeight:
                                                                fullMenu
                                                                    ?.presenter_template
                                                                    ?.fonts
                                                                    ?.section_links
                                                                    ?.weight,
                                                            fontSize:
                                                                fullMenu
                                                                    ?.presenter_template
                                                                    ?.fonts
                                                                    ?.section_links
                                                                    ?.size,
                                                            fontStyle:
                                                                fullMenu
                                                                    ?.presenter_template
                                                                    ?.fonts
                                                                    ?.section_links
                                                                    ?.style,
                                                            lineHeight:
                                                                fullMenu
                                                                    ?.presenter_template
                                                                    ?.fonts
                                                                    ?.section_links
                                                                    ?.line_height,
                                                            color: fullMenu
                                                                ?.presenter_template
                                                                ?.fonts
                                                                ?.section_links
                                                                ?.color
                                                        }}
                                                    >
                                                        {
                                                            link.section_id
                                                                .display_name
                                                        }
                                                    </Typography>
                                                </Grid>
                                            );
                                        }
                                    )}
                                </Grid>
                                {menu?.menu_id?.section_links?.map(
                                    (section, i: number) => {
                                        return (
                                            <Box
                                                key={
                                                    section.section_id
                                                        .display_name + i
                                                }
                                                ref={
                                                    refs[section.section_id._id]
                                                }
                                            >
                                                <Box
                                                    key={section.section_id._id}
                                                >
                                                    <Section
                                                        sx={{
                                                            background: `${
                                                                primaryBackgroundColor
                                                                    ? primaryBackgroundColor
                                                                    : `#ffffff`
                                                            }`,

                                                            color: fullMenu
                                                                ?.presenter_template
                                                                ?.fonts
                                                                ?.section_links
                                                                ?.color,
                                                            m: 0
                                                        }}
                                                        sectionTitle={
                                                            section.section_id
                                                                .display_name
                                                        }
                                                        sectionDescription={
                                                            section.section_id
                                                                .header_text
                                                        }
                                                        items={
                                                            section.section_id
                                                                .item_links
                                                        }
                                                        showNotations={
                                                            showNotations
                                                        }
                                                        defaultExpanded={true}
                                                        alwaysExpanded={true}
                                                        id={
                                                            section.section_id
                                                                ._id
                                                        }
                                                        internalName={
                                                            section.section_id
                                                                .internal_name ||
                                                            section.section_id
                                                                .display_name
                                                        }
                                                        key={
                                                            section.section_id
                                                                ._id
                                                        }
                                                    ></Section>
                                                </Box>
                                            </Box>
                                        );
                                    }
                                )}
                            </>
                        )}
                    </Grid>
                    <Box
                        sx={{
                            left: "0px",
                            top: "0px",
                            overflow: "hidden",
                            margin: "0px",
                            padding: "0px",
                            height: "1037px",
                            width: "572px",
                            zIndex: -999999,
                            position: "fixed",
                            display: {
                                xl: "none",
                                lg: "none",
                                sm: "none"
                            }
                        }}
                    >
                        <Card>
                            <CardMedia
                                style={{
                                    position: "absolute",
                                    margin: "0px",
                                    padding: "0px",
                                    border: "none",
                                    width: "90%",
                                    height: "1231px",
                                    maxHeight: "none",
                                    maxWidth: "none",
                                    zIndex: -999999,
                                    top: "60px"
                                }}
                                image={featuredImage}
                            />
                        </Card>
                    </Box>
                </Grid>

                <BackToTop
                    backgroundColor={
                        fullMenu?.presenter_template.colors?.tertiary || "grey"
                    }
                    color={
                        fullMenu?.presenter_template.colors?.primary || "black"
                    }
                ></BackToTop>
            </Container>
        </LayoutThree>
    );
}
