import { styled } from "@mui/material/styles";
import { IPresentTemplate } from "../../../../const";

type Props = {
    template?: IPresentTemplate;
};

const classes = {
    searchBarContainer: `searchBarContainer`,
    searchBar: `searchBar`,
    fabAdornamentQuit: `fabAdornamentQuit`,
    fabAdornament: `fabAdornament`,
    badge: `badge`,
    main: `main`
};

const Root = styled("div")<Props>(({ template }) => ({
    [`&.${classes.searchBarContainer}`]: {
        display: "flex",
        alignItems: "center",
        flexWrap: "nowrap",
        justifyContent: "space-between",
        marginBottom: "20px"
    },
    [`& .${classes.searchBar}`]: {
        width: "85%",
        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                borderRadius: `30px 30px 30px 30px`
            },
            "&.Mui-focused fieldset": {
                borderColor: template?.colors?.background_2 || "#CC5803"
            }
        },
        "& label.Mui-focused": {
            color: template?.colors?.background_2 || "#CC5803"
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: template?.colors?.background_2 || "#CC5803"
        }
    },
    [`& .${classes.fabAdornamentQuit}`]: {
        backgroundColor: "transparent",
        color: "#fff !important",
        transform: "scale(.7)"
    },
    [`& .${classes.fabAdornament}`]: {
        backgroundColor: `${template?.colors?.primary || "#CC5803"} !important`,
        color: "#fff !important",
        transform: "scale(.7)"
    },
    [`& .${classes.badge}`]: {
        backgroundColor: template?.colors?.primary || "#CC5803",
        color: "#ffff",
        fontWeight: "600 !important"
    },
    "& .cartIcon": {
        "& path": {
            fill: `${template?.colors?.secondary || "#F6C19F"}`
        }
    },
    [`& .${classes.main}`]: {}
}));

export { Root, classes };
