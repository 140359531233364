import React from "react";
import { EVENT_PAYLOADS, TrackContextInterface } from "../const/events";
import { Tracker } from "../utils/Tracker";

const TrackContext = React.createContext<Partial<TrackContextInterface>>({});
TrackContext.displayName = "TContext";

function TrackContextProvider(props: any) {
    const newEvent = function <
        EVENT extends keyof typeof EVENT_PAYLOADS,
        PROPS extends (typeof EVENT_PAYLOADS)[EVENT]
    >(event: EVENT, params: PROPS, restaurantIdentifier: string) {
        return Tracker.getInstance().addEvent({
            eventName: event,
            eventPayload: params,
            restaurantIdentifier
        });
    };

    const value = React.useMemo(
        () => ({
            newEvent
        }),
        []
    );

    return <TrackContext.Provider value={value} {...props} />;
}

/**
 * This is a hook that allows access to the track context object.
 */
function useTrackContext() {
    const context = React.useContext(TrackContext) as TrackContextInterface;
    if (!context) {
        throw new Error(
            "TrackContext must be used within a TrackContextProvider"
        );
    }
    return context;
}

export { TrackContext, TrackContextProvider, useTrackContext };
