// This is a singleton class, so we can only have one instance of it.
// We can use it to track events, and send them to the server.
// We can also use it to track errors, and send them to the server.

import { client } from "../apiTrackClient";
import { IEventQueue } from "./definitions";
import Cookie from "js-cookie";
import {
    PATRON_COOKIE_EXPIRE,
    PATRON_COOKIE_NAME,
    TRACK_VERSION
} from "../../const/events";
import { generateId } from "../uuid";
import { isLocalStorageAvailable } from "../localStorage";

// We can also use it to track page views, and send them to the server.
export class Tracker {
    private static tracker: Tracker;
    events: IEventQueue[] = [];

    private constructor() {}

    public static getInstance(): Tracker {
        if (!Tracker.tracker) {
            Tracker.tracker = new Tracker();
        }
        return Tracker.tracker;
    }

    public addEvent(event: IEventQueue) {
        if (!isLocalStorageAvailable()) {
            return;
        }
        const patronCookie = Cookie.get()[PATRON_COOKIE_NAME];
        const patronLocalStorage = localStorage.getItem(PATRON_COOKIE_NAME);
        if (!patronCookie || !patronLocalStorage) {
            this.generatePatron();
        }
        if (!patronCookie && !patronLocalStorage) {
            this.events.unshift({
                restaurantIdentifier: event.restaurantIdentifier,
                eventName: "patron-created",
                eventPayload: { template: event.eventPayload.template }
            });
        }
        this.events.push(event);

        this.track();
    }

    private generatePatron() {
        const patronCookie = Cookie.get()[PATRON_COOKIE_NAME];
        const patronLocalStorage = localStorage.getItem(PATRON_COOKIE_NAME);
        const patronId = patronCookie || patronLocalStorage || generateId();

        Cookie.set(PATRON_COOKIE_NAME, patronId, {
            domain: process.env.REACT_APP_COOKIE_DOMAIN,
            expires: PATRON_COOKIE_EXPIRE
        });

        localStorage.setItem(PATRON_COOKIE_NAME, patronId);
    }

    private async track() {
        let event = this.events.shift();

        while (event) {
            try {
                const res = await client(
                    `track/${event.restaurantIdentifier}/${event.eventName}`,
                    {
                        body: {
                            ...event.eventPayload,
                            track_version: TRACK_VERSION
                        },
                        headers: {
                            "Content-Type": "application/json",
                            patron_id: localStorage.getItem(PATRON_COOKIE_NAME)
                        }
                    }
                );
                console.log(event.eventName, res.message);
            } catch (e) {
                console.log(e);
            }
            event = this.events.shift();
        }
    }
}
