import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";

type Props = {
    template?: Record<string, any>;
};
const classes = {
    itemContainer: `itemContainer`,
    itemTitle: `itemTitle`,
    itemDescription: `itemDescription`,
    itemPrice: `itemPrice`,
    loadingContainer: `loadingContainer`,
    sectionDescription: `sectionDescription`,
    notationConatiner: `notationConatiner`,
    notation: `notation`,
    notationSeparator: `notationSeparator`,
    showNotationButtonContainer: `showNotationButtonContainer`,
    itemButton: `itemButton`,
    showNotationButton: `showNotationButton`
};

const Root = styled(Box)<Props>(({ template }) => ({
    [`&.${classes.itemContainer}`]: {},
    [`& .${classes.itemTitle}`]: {
        margin: "1rem 0 .2rem 0",
        padding: "0 0 0 0",
        fontSize: "1.1rem",
        lineHeight: "1.3rem",
        fontWeight: "600",
        fontFamily: "Inter",
        textAlign: "left"
    },
    [`& .${classes.itemDescription}`]: {
        fontFamily: "Inter",
        margin: "0 0 .3rem 0",
        padding: "0 0 0 0",
        fontSize: ".9rem",
        lineHeight: "1.3rem",
        textAlign: "left"
    },
    [`& .${classes.itemPrice}`]: {
        fontFamily: "Inter",
        padding: "0 0 0 0",
        fontSize: ".9rem",
        lineHeight: "1.1rem",
        textAlign: "left",
        margin: ".3rem 0 0 0"
    },
    [`& .${classes.loadingContainer}`]: {
        width: "100vw",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    [`& .${classes.sectionDescription}`]: {
        fontFamily: "Inter",
        fontSize: "1rem",
        textAlign: "center",
        fontWeight: "600",
        lineHeight: "1.3",
        fontStyle: "italic",
        whiteSpace: "pre-line",
        marginBottom: "20px"
    },
    [`& .${classes.notationConatiner}`]: {
        display: "flex",
        justifyContent: "flex-start",
        margin: "0 0 1.3rem 0"
    },
    [`& .${classes.notation}`]: {
        margin: "0 0 0 0",
        display: "inline-block",
        fontSize: "12px",
        fontWeight: "400",
        lineHeight: "12.89px",
        fontFamily: "Inter",
        color: template?.primaryColor || "red"
    },
    [`& .${classes.notationSeparator}`]: {
        height: "4.3px",
        width: "4.3px",
        backgroundColor: "#9E9E9E",
        borderRadius: "50%",
        margin: "0 8px 2px 8px",
        display: "inline-block"
    },
    [`& .${classes.showNotationButtonContainer}`]: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    [`& .${classes.showNotationButton}`]: {}
}));

export { Root, classes };
