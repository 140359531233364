/* eslint-disable react-hooks/exhaustive-deps */
import { Container } from "@mui/material";
import React from "react";
import { useParams } from "react-router-dom";
import { useGlobalContext } from "../../../context/Global";
import { useTrackContext } from "../../../context/Tracking";
import LayoutOne from "../Components/Layout";
import { Root, classes } from "./styles";

export function Collection1(props: any) {
    const { fullMenu, collection, setMenu } = useGlobalContext();
    const { newEvent } = useTrackContext();
    const { restaurant: restaurantIdentifier } = useParams();

    React.useEffect(() => {
        setMenu && setMenu(null);
        collection && launchEvent();
    }, [newEvent, collection]);

    // This effect takes care of the background color of the body
    const bodyWrapper = document.querySelector(".body-wrapper") as HTMLElement;
    React.useEffect(() => {
        const backgroundColor =
            fullMenu?.presenter_template.colors?.background_1 || "#fff";
        const className = "bg-" + backgroundColor;
        //  add class to body element
        document.body.classList.add(className);

        //  set style on body element
        bodyWrapper.style.backgroundColor = backgroundColor;

        return () => {
            //  optionally remove styles when component unmounts
            bodyWrapper.style.backgroundColor = "";

            document.body.classList.remove(className);
        };
    }, []);

    function launchEvent() {
        if (
            typeof collection?.collection_id !== "undefined" &&
            restaurantIdentifier
        ) {
            newEvent(
                "collection-view",
                {
                    _id: collection.collection_id._id,
                    name:
                        collection.collection_id.internal_name ||
                        collection.collection_id.display_name,
                    template: "1"
                },
                restaurantIdentifier
            );
        }
    }

    return (
        <LayoutOne
            title={fullMenu?.display_name}
            subtitle={collection?.collection_id?.display_name}
            showSearch={false}
            showNotationToggle
            showNavbar
            templateConfigs={{}}
            template={fullMenu?.presenter_template || {}}
        >
            <Container maxWidth="md">
                <Root style={{ padding: "0 20px 0 20px" }}>
                    <div className={classes.welcomeMessage}>
                        {collection?.collection_id?.menu_landing_message}
                    </div>
                    <div className={classes.welcomeMessage}>
                        {collection?.collection_id?.menu_landing_message_2}
                    </div>
                    <div
                        className={classes.logoContainer}
                        style={{
                            display:
                                fullMenu?.presenter_template
                                    ?.showRestaurantImage && fullMenu.logo_image
                                    ? "flex"
                                    : "none"
                        }}
                    >
                        <img
                            className={classes.logo}
                            src={fullMenu?.logo_image}
                            alt="This needs to be changed."
                        />
                    </div>
                </Root>
            </Container>
        </LayoutOne>
    );
}
