/* eslint-disable import/no-webpack-loader-syntax */
import { Badge, Fab } from "@mui/material";
import React from "react";
import { IPresentTemplate } from "../../../../const";
import { Root, classes } from "./styles";
import FacebookIcon from "-!svg-react-loader!../../../../assets/icons/facebook.svg";
import InstagramIcon from "-!svg-react-loader!../../../../assets/icons/instagram.svg";
import TwitterIcon from "-!svg-react-loader!../../../../assets/icons/twitter.svg";
import PinterestIcon from "-!svg-react-loader!../../../../assets/icons/pinterest.svg";
import YelpIcon from "-!svg-react-loader!../../../../assets/icons/yelp.svg";
import TiktokIcon from "-!svg-react-loader!../../../../assets/icons/tiktok.svg";
import { useGlobalContext } from "../../../../context/Global";
import { useIntl } from "react-intl";

function SocialNetwork(props: {
    color: string;
    Icon: any;
    link: string;
    name: string;
    background?: string;
}) {
    const { color, background, Icon, link, name } = props;
    const intl = useIntl();

    return (
        <div>
            <Root
                color={color}
                background={background}
                title={`${intl.messages["go_to"]} ${name}`}
                className={classes.toolbar}
                onClick={() => window.open(link, "_blank")}
            >
                <Fab size="large">
                    <Badge>
                        <Icon className={classes.searchIcon}></Icon>
                    </Badge>
                </Fab>
            </Root>
        </div>
    );
}

export default function ShowSocialNetworks(props: {
    template: Partial<IPresentTemplate>;
}) {
    const { template } = props;
    const { fullMenu } = useGlobalContext();
    const [socialNetworks, setSocialNetworks] = React.useState<
        {
            name: string;
            Icon: any;
            color: string;
            link: string;
            background: string;
        }[]
    >([]);

    React.useEffect(() => {
        if (fullMenu?.social_media) {
            const socialNetworksArray: {
                name: string;
                Icon: any;
                color: string;
                link: string;
                background: string;
            }[] = [];
            if (
                fullMenu.social_media.facebook &&
                template.social_media?.facebook?.show_social_media
            ) {
                socialNetworksArray.push({
                    name: "Facebook",
                    Icon: FacebookIcon,
                    color: template.social_media?.facebook?.color || "#3b5998",
                    link: fullMenu.social_media.facebook,
                    background:
                        template.social_media?.facebook?.background || "#FFFFFF"
                });
            }
            if (
                fullMenu.social_media.instagram &&
                template.social_media?.instagram?.show_social_media
            ) {
                socialNetworksArray.push({
                    name: "Instagram",
                    Icon: InstagramIcon,
                    color: template.social_media?.instagram?.color || "#e1306c",
                    link: fullMenu.social_media.instagram,
                    background:
                        template.social_media?.instagram?.background ||
                        "#FFFFFF"
                });
            }
            if (
                fullMenu.social_media.twitter &&
                template.social_media?.twitter?.show_social_media
            ) {
                socialNetworksArray.push({
                    name: "Twitter",
                    Icon: TwitterIcon,
                    color: template.social_media?.twitter?.color || "#1da1f2",
                    link: fullMenu.social_media.twitter,
                    background:
                        template.social_media?.twitter?.background || "#FFFFFF"
                });
            }
            if (
                fullMenu.social_media.pinterest &&
                template.social_media?.pinterest?.show_social_media
            ) {
                socialNetworksArray.push({
                    name: "Pinterest",
                    Icon: PinterestIcon,
                    color: template.social_media?.pinterest?.color || "#bd081c",
                    link: fullMenu.social_media.pinterest,
                    background:
                        template.social_media?.pinterest?.background ||
                        "#FFFFFF"
                });
            }
            if (
                fullMenu.social_media.yelp &&
                template.social_media?.yelp?.show_social_media
            ) {
                socialNetworksArray.push({
                    name: "Yelp",
                    Icon: YelpIcon,
                    color: template.social_media?.yelp?.color || "#d32323",
                    link: fullMenu.social_media.yelp,
                    background:
                        template.social_media?.yelp?.background || "#FFFFFF"
                });
            }
            if (
                fullMenu.social_media.tiktok &&
                template.social_media?.tiktok?.show_social_media
            ) {
                socialNetworksArray.push({
                    name: "Tiktok",
                    Icon: TiktokIcon,
                    color: template.social_media?.tiktok?.color || "#000000",
                    link: fullMenu.social_media.tiktok,
                    background:
                        template.social_media?.tiktok?.background || "#FFFFFF"
                });
            }
            setSocialNetworks(socialNetworksArray);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fullMenu]);

    return (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                //width: "200px",
                alignItems: "align-self"
            }}
        >
            {socialNetworks.map((socialNetwork, index) => (
                <SocialNetwork
                    key={socialNetwork.name + index}
                    name={socialNetwork.name}
                    Icon={socialNetwork.Icon}
                    color={socialNetwork.color}
                    background={socialNetwork.background}
                    link={socialNetwork.link}
                ></SocialNetwork>
            ))}
        </div>
    );
}
