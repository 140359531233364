import { styled } from "@mui/material/styles";

const classes = {
    container: `container`,
    sectionContainer: `sectionContainer`,
    sectionImageContainer: `sectionImageContainer`,
    sectionTitle: `sectionTitle`,
    itemContainer: `itemContainer`,
    itemTitle: `itemTitle`,
    itemDescription: `itemDescription`,
    itemPrice: `itemPrice`,
    loadingContainer: `loadingContainer`,
    sectionDescription: `sectionDescription`,
    notationConatiner: `notationConatiner`,
    notation: `notation`,
    showNotationButtonContainer: `showNotationButtonContainer`,
    showNotationButton: `showNotationButton`
};

const Root = styled("div")(() => ({
    [`& .${classes.container}`]: {
        justifyContent: "center"
    },
    [`& .${classes.sectionContainer}`]: {
        padding: "0 1rem 1rem",
        border: "1px solid #ededed",
        borderRadius: "3px",
        boxShadow: "0 4px 8px 0 rgb(0 0 0 / 20%)",
        backgroundColor: "#fff"
    },
    [`& .${classes.sectionImageContainer}`]: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    [`& .${classes.sectionTitle}`]: {
        borderBottom: "#003 1px solid",
        padding: ".2rem 0 0.5rem",
        textAlign: "center",
        fontSize: "1.4rem",
        fontWeight: "600",
        fontFamily: "Inter"
    },
    [`& .${classes.itemContainer}`]: {
        marginBottom: "25px"
    },
    [`& .${classes.itemTitle}`]: {
        whiteSpace: "pre-line",
        padding: "0 0 .1rem 0",
        fontSize: "1rem",
        fontFamily: "Inter",
        textAlign: "center",
        margin: "-5px 0 -13px 0"
    },
    [`& .${classes.itemDescription}`]: {
        fontFamily: "Inter",
        padding: "0 0 0 0",
        fontSize: ".9rem",
        textAlign: "center"
    },
    [`& .${classes.itemPrice}`]: {
        fontFamily: "Inter",
        padding: "0 0 0 0",
        fontSize: ".9rem",
        textAlign: "center",
        marginTop: "-10px"
    },
    [`& .${classes.loadingContainer}`]: {
        width: "100vw",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    [`& .${classes.sectionDescription}`]: {
        fontFamily: "Inter",
        fontSize: "1rem",
        textAlign: "center",
        fontWeight: "600",
        lineHeight: "1.3",
        fontStyle: "italic",
        whiteSpace: "pre-line",
        marginBottom: "20px"
    },
    [`& .${classes.notationConatiner}`]: {
        display: "flex",
        justifyContent: "center",
        margin: "18px 0 -10px 0"
    },
    [`& .${classes.notation}`]: {
        display: "inline-block",
        border: "1px solid black",
        borderRadius: "5px",
        fontSize: ".8rem",
        fontWeight: "300",
        fontFamily: "Inter",
        padding: ".1rem .5rem .1rem .5rem",
        margin: "3px 3px 3px 3px"
    },
    [`& .${classes.showNotationButtonContainer}`]: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    [`& .${classes.showNotationButton}`]: {}
}));

export { Root, classes };
