import { styled } from "@mui/material/styles";

const classes = {
    welcomeMessage: `welcomeMessage`,
    logoContainer: `logoContainer`,
    logo: `logo`
};

const Root = styled("div")(() => ({
    [`& .${classes.welcomeMessage}`]: {
        fontFamily: "Inter",
        fontSize: "19.2px",
        marginTop: "30px",
        textAlign: "center",
        fontWeight: "400",
        whiteSpace: "pre-line"
    },
    [`& .${classes.logoContainer}`]: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "20px 0 20px 0"
    },
    [`& .${classes.logo}`]: {
        maxWidth: "250px",
        maxHeight: "200px"
    }
}));

export { Root, classes };
