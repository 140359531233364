import { styled } from "@mui/material/styles";
import { IPresentTemplate } from "../../../../const";

type Props = {
    template?: Partial<IPresentTemplate>;
};

const classes = {
    footer: `footer`,
    footerContainer: `footerContainer`,
    footerCustomMessage: `footerCustomMessage`,
    footerMessage: `footerMessage`,
    footerMessage2: `footerMessage2`
};

const Root = styled("footer")<Props>(({ template }) => ({
    [`&.${classes.footer}`]: {
        width: "100%",
        height: "118px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    [`& .${classes.footerContainer}`]: {},
    [`& .${classes.footerCustomMessage}`]: {
        fontFamily: "Inter",
        fontSize: "9pt",
        fontWeight: "200",
        textAlign: "center",
        color: "#22333b",
        marginBottom: "40px"
    },
    [`& .${classes.footerMessage}`]: {
        fontFamily: template?.fonts?.footer?.family || "Inter",
        fontSize: template?.fonts?.footer?.size || "9pt",
        fontWeight: template?.fonts?.footer?.weight || "200",
        color: template?.fonts?.footer?.color || "#22333b",
        lineHeight: template?.fonts?.footer?.line_height || "1",
        fontStyle: template?.fonts?.footer?.style || "normal",
        textAlign: "center"
    },
    [`& .${classes.footerMessage2}`]: {
        textAlign: "center",
        fontFamily: template?.fonts?.footer?.family || "Inter",
        fontSize: template?.fonts?.footer?.size || "9pt",
        fontWeight: template?.fonts?.footer?.weight || "200",
        color: template?.fonts?.footer?.color || "#22333b",
        lineHeight: template?.fonts?.footer?.line_height || "1",
        fontStyle: template?.fonts?.footer?.style || "normal"
    }
}));

export { Root, classes };
