/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Root, classes } from "./styles";
import { useNavigate } from "react-router-dom";
import { useGlobalContext } from "../../../context/Global";
import LayoutThree from "../Components/Layout";

export default function Restaurant2(props: any) {
    const navigate = useNavigate();

    const { fullMenu } = useGlobalContext();
    const data = fullMenu?.presenter_template;

    React.useEffect(() => {
        if (fullMenu?.collection_links?.length === 1) {
            navigate(
                `/${fullMenu.identifier}/${fullMenu.collection_links[0]?.collection_id?._id}/${fullMenu.collection_links[0].collection_id?.menu_links[0]?.menu_id?._id}`,
                {
                    replace: true
                }
            );
        }
    }, []);

    const headerImage = fullMenu?.presenter_template.images?.header_image;
    return (
        <LayoutThree
            templateConfigs={{}}
            title={headerImage ? headerImage : fullMenu?.display_name}
            showSearch={false}
            showNavbar={false}
        >
            <Root
                template={data}
                maxWidth="md"
                className={classes.gridContainer}
            >
                {fullMenu?.collection_links?.map((collection) => (
                    <div
                        key={collection.collection_id._id}
                        className={classes.collectionContainer}
                        onClick={() => {
                            navigate(
                                `/${fullMenu.identifier}/${collection.collection_id._id}`
                            );
                        }}
                    >
                        <h4 className={classes.secondaryMessage}>
                            {collection.collection_id.display_name}
                        </h4>
                        <p className={classes.description}>
                            {collection.collection_id.schedule_landing_message}
                        </p>
                    </div>
                ))}
            </Root>
        </LayoutThree>
    );
}
