/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Grid } from "@mui/material";
import React from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useGlobalContext } from "../../../context/Global";
import { useTrackContext } from "../../../context/Tracking";
import Layout998 from "../Components/Layout";
import { Root, classes } from "./styles";

// type NewEventType =  ;

export default function Menu998(props: any) {
    const { fullMenu, menu } = useGlobalContext();
    const [searchParams, setSearchParams] = useSearchParams();
    const { newEvent } = useTrackContext();
    const { restaurant: restaurantIdentifier } = useParams();

    const [showNotations, setShowNotations] = React.useState<boolean>(false);

    React.useEffect(() => {
        const notationState =
            searchParams.get("notations") === "true" ? true : false;
        setShowNotations(notationState);
    }, [searchParams]);

    React.useEffect(() => {
        if (typeof menu?.menu_id !== "undefined" && restaurantIdentifier) {
            newEvent(
                "menu-view",
                {
                    _id: menu?.menu_id?._id,
                    name:
                        menu?.menu_id?.internal_name ||
                        menu?.menu_id?.display_name,
                    template: "999",
                    notations: showNotations,
                    expanded: true
                },
                restaurantIdentifier
            );
        } else {
            return;
        }
    }, [newEvent, menu]);

    function onShowNotations() {
        const notationState =
            searchParams.get("notations") === "true" ? "false" : "true";
        setSearchParams({ notations: notationState });
        window.scrollTo(0, 0);
    }

    return (
        <Layout998 showTitle={false} templateConfigs={{}}>
            <Root>
                <Grid container className={classes.container}>
                    {menu?.menu_id?.section_links?.map((section, i: number) => (
                        <React.Fragment
                            key={section.section_id.display_name + i}
                        >
                            <Grid
                                item
                                xs={12}
                                style={{ height: "20px" }}
                            ></Grid>
                            <Grid
                                item
                                xs={11}
                                md={9}
                                lg={8}
                                className={classes.sectionContainer}
                                style={{
                                    ...(fullMenu?.presenter_template.border && {
                                        border: fullMenu?.presenter_template
                                            .border
                                    })
                                }}
                            >
                                {fullMenu?.presenter_template
                                    ?.showSectionImage &&
                                section?.section_id?.section_image ? (
                                    <div
                                        className={
                                            classes.sectionImageContainer
                                        }
                                    >
                                        <img
                                            src={
                                                section.section_id.section_image
                                            }
                                            alt={`Imagen seccion ${section.section_id.display_name}`}
                                            style={{ maxWidth: "90%" }}
                                        ></img>
                                    </div>
                                ) : (
                                    <h3 className={classes.sectionTitle}>
                                        {section.section_id.display_name}
                                    </h3>
                                )}
                                <div className={classes.sectionDescription}>
                                    {section.section_id.header_text}
                                </div>
                                {section.section_id.item_links.map(
                                    (item, i: number) => (
                                        <div
                                            className={classes.itemContainer}
                                            key={item.item_id?.display_name + i}
                                        >
                                            <h4
                                                className={classes.itemTitle}
                                                style={{
                                                    ...(item.item_id?.type ===
                                                        "title" && {
                                                        fontSize: "1.3rem",
                                                        fontWeight: "500",
                                                        lineHeight: "1.3",
                                                        fontStyle: "italic",
                                                        textDecoration:
                                                            "underline"
                                                    })
                                                }}
                                            >
                                                {item.item_id?.display_name}
                                            </h4>
                                            <div
                                                className={
                                                    classes.notationConatiner
                                                }
                                            >
                                                {showNotations &&
                                                    item.item_id?.notation_links?.map(
                                                        (notation) => (
                                                            <span
                                                                className={
                                                                    classes.notation
                                                                }
                                                                key={
                                                                    notation
                                                                        .notation_id
                                                                        ?._id
                                                                }
                                                            >
                                                                {
                                                                    notation
                                                                        .notation_id
                                                                        ?.display_name
                                                                }
                                                            </span>
                                                        )
                                                    )}
                                            </div>
                                            <p
                                                className={
                                                    classes.itemDescription
                                                }
                                            >
                                                {item.item_id?.description}
                                            </p>
                                            <p className={classes.itemPrice}>
                                                {
                                                    item.item_id
                                                        ?.price_variations[0]
                                                        ?.price
                                                }
                                            </p>
                                        </div>
                                    )
                                )}
                            </Grid>
                        </React.Fragment>
                    ))}
                </Grid>
                <Grid
                    item
                    xs={12}
                    className={classes.showNotationButtonContainer}
                    style={{
                        height: menu?.menu_id?.show_notations ? "100px" : "20px"
                    }}
                >
                    {menu?.menu_id?.show_notations && (
                        <Button
                            variant="contained"
                            color="primary"
                            className={classes.showNotationButton}
                            onClick={onShowNotations}
                        >
                            {showNotations
                                ? "Hide Dietary Restrictions & Allergies"
                                : "Show Dietary Restrictions & Allergies"}
                        </Button>
                    )}
                </Grid>
            </Root>
        </Layout998>
    );
}
