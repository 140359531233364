import { styled } from "@mui/material/styles";
import { IPresentTemplate } from "../../../../const";

type Props = {
    template?: IPresentTemplate;
};

const classes = {
    container: `container`,
    title: `title`,
    content: `content`,
    image: `image`,
    clear: `clear`,
    searchIcon: `searchIcon`
};

const Root = styled("div")<Props>(({ template }) => ({
    [`&.${classes.container}`]: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    },
    [`& .${classes.title}`]: {
        fontFamily: "Inter",
        fontSize: "16px",
        fontWeight: "500",
        textAlign: "center",
        marginBottom: "0px"
    },
    [`& .${classes.content}`]: {
        fontFamily: "Inter",
        fontSize: "12px",
        fontWeight: "300",
        textAlign: "center"
    },
    [`& .${classes.image}`]: {
        width: "70px",
        height: "70px"
    },
    [`& .${classes.clear}`]: {
        marginTop: "30px",
        fontFamily: "Inter",
        fontSize: "18px",
        fontWeight: "400",
        textAlign: "center",
        textDecoration: "underline",
        color: "#2d2d2d"
    },
    [`& .${classes.searchIcon}`]: {
        "& path, circle": {
            fill: template?.colors?.primary || "black"
        },
        width: "70px",
        height: "70px"
    }
}));

export { Root, classes };
