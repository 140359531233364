import { SECTION_LOCAL_STORAGE } from "../const/sections";
import { isLocalStorageAvailable } from "./localStorage";

interface ISections {
    sections: { [key: string]: boolean };
}

export class Section {
    restaurantIdentifier: string;
    constructor(restaurantIdentifier: string) {
        this.restaurantIdentifier = restaurantIdentifier;
    }

    private getName() {
        return `${SECTION_LOCAL_STORAGE}_${this.restaurantIdentifier}`;
    }

    /**
     * This method validates the item in local storage is there and has a valid structure.
     */
    private validateSections() {
        const sections = window.localStorage.getItem(this.getName()) as string;

        let sectionsObj: ISections = { sections: {} };

        if (!sections) {
            return localStorage.setItem(
                this.getName(),
                JSON.stringify(sectionsObj)
            );
        }

        try {
            sectionsObj = JSON.parse(sections);
        } catch (e) {
            console.log(e);
            return localStorage.setItem(
                this.getName(),
                JSON.stringify(sectionsObj)
            );
        }

        if (!sectionsObj.sections || typeof sectionsObj.sections !== "object") {
            return localStorage.setItem(
                this.getName(),
                JSON.stringify(sectionsObj)
            );
        }
    }

    /**
     * This method create all the logic to save the section state.
     * @param {string} sectionId - The ID of the section to update.
     * @param {boolean} state - The state of the section, .
     */
    setSectionState(sectionId: string, state: boolean) {
        if (!isLocalStorageAvailable()) {
            return;
        }
        this.validateSections();
        const sections = window.localStorage.getItem(this.getName()) as string;

        const sectionsObj: ISections = JSON.parse(sections);

        if (state) {
            sectionsObj.sections[sectionId] = true;
        } else {
            sectionsObj.sections[sectionId] = false;
        }

        localStorage.setItem(this.getName(), JSON.stringify(sectionsObj));

        return sectionsObj.sections;
    }

    /**
     * This method returns the sections object.
     */
    getSections() {
        if (!isLocalStorageAvailable()) {
            return {};
        }
        this.validateSections();
        const sections = window.localStorage.getItem(this.getName()) as string;

        const sectionsObj: ISections = JSON.parse(sections);
        return sectionsObj.sections;
    }

    /**
     * This method clears the sections object.
     */
    clearSections() {
        if (!isLocalStorageAvailable()) {
            return;
        }
        let sectionsObj: ISections = { sections: {} };
        return window.localStorage.setItem(
            this.getName(),
            JSON.stringify(sectionsObj)
        );
    }
}
