import React from "react";
import { useGlobalContext } from "../../../../context/Global";
import { Root, classes } from "./styles";
// eslint-disable-next-line import/no-webpack-loader-syntax
import NoSearchIcon from "-!svg-react-loader!../../../../assets/icons/failedsearch.svg";
import { useIntl } from "react-intl";

export default function NoSearchResults(props: { isFavoritePage: boolean }) {
    const { isFavoritePage } = props;
    const { fullMenu, setSearch } = useGlobalContext();
    const data = fullMenu?.presenter_template;

    const intl = useIntl();

    return (
        <Root template={data} className={`${classes.container} no-favorites`}>
            <NoSearchIcon className={classes.searchIcon}></NoSearchIcon>
            <h5 className={classes.title}>
                {isFavoritePage
                    ? intl.formatMessage({
                          id: "favorites.empty_title"
                      })
                    : intl.formatMessage({
                          id: "empty_search"
                      })}
            </h5>
            <p className={classes.content}>
                {isFavoritePage
                    ? intl.formatMessage({
                          id: "favorites.empty_text"
                      })
                    : intl.formatMessage({
                          id: "empty_search_suggest"
                      })}
            </p>
            <p
                style={{ display: isFavoritePage ? "none" : "block" }}
                className={classes.clear}
                onClick={() => setSearch && setSearch("")}
            >
                {intl.formatMessage({
                    id: "favorites.clear"
                })}
            </p>
        </Root>
    );
}
