import React, { useCallback, useEffect } from "react";
import "./App.css";
import Routing from "./routes/router";
import { ThemeProvider } from "@mui/material/styles";
import mainTheme from "./themes/default";

function App({ version: localVersion }: { version: string }) {
    const isUpdated = useCallback(
        (targetVersion: string) => {
            // Compare local version with server(target) version to see if a hard reload is required
            const [localMajor, localMinor, localPatch] = localVersion
                .split(".")
                .map(Number);
            const [serverMajor, serverMinor, serverPatch] = targetVersion
                .split(".")
                .map(Number);

            if (localMajor < serverMajor) return false;
            if (localMinor < serverMinor) return false;
            if (localPatch < serverPatch) return false;
            return true;
        },
        [localVersion]
    );

    const handleHardReload = async () => {
        //Reload all the assets ignoring the cache
        const assets = performance
            .getEntries()
            .filter((x) => x.name.match(new RegExp(".+main.+|.+bundle.+")))
            .map((x) => x.name);
        await fetch(window.location.href, {
            headers: {
                Pragma: "no-cache",
                Expires: "-1",
                "Cache-Control": "no-cache"
            }
        });
        for (const url of assets) {
            await fetch(url, {
                headers: {
                    Pragma: "no-cache",
                    Expires: "-1",
                    "Cache-Control": "no-cache"
                }
            });
        }
        window.location.href.includes("updated") &&
            //@ts-ignore
            window.location.reload(true);
        !window.location.href.includes("updated") &&
            (window.location.href = window.location.href + "?updated");
    };

    const fetchVersion = useCallback(async () => {
        //Get the server version and comare it with the local
        const baseUrl = window.location.origin;
        console.log("Using version " + localVersion);
        const response = await fetch(
            `${baseUrl}/meta.json?no-cache=${Date.now()}`
        );
        const { version } = await response.json();
        const hasLatestVersion = isUpdated(version);
        if (!hasLatestVersion) {
            handleHardReload();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isUpdated]);

    useEffect(() => {
        // Check for new versions when the page is loaded
        fetchVersion();
    }, [fetchVersion]);

    return (
        <ThemeProvider theme={mainTheme}>
            <Routing></Routing>
        </ThemeProvider>
    );
}

export default App;
