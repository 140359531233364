import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderOutlined";
import BookmarkOutlinedIcon from "@mui/icons-material/BookmarkOutlined";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Fab,
    Typography,
    Tooltip,
    Box
} from "@mui/material";
import _ from "lodash";
import React from "react";
import { useParams } from "react-router-dom";
import { useGlobalContext } from "../../../../context/Global";
import { useTrackContext } from "../../../../context/Tracking";
import { Favorites } from "../../../../utils/favorites";
import { Root, classes } from "./styles";
import { SxProps } from "@mui/system";
import { IItem } from "../../../../const";
import { showPrice } from "../../../../utils/price";
import { useIntl } from "react-intl";

export default function Section(props: {
    sectionTitle: string;
    sectionDescription: string;
    items: Array<{ item_id: IItem }>;
    showNotations: boolean;
    defaultExpanded: boolean;
    alwaysExpanded?: boolean;
    id: string;
    internalName: string;
    sx?: SxProps;
}) {
    const {
        sectionTitle,
        sectionDescription,
        items,
        showNotations,
        defaultExpanded,
        alwaysExpanded = false,
        id,
        internalName,
        sx
    } = props;
    const { fullMenu, allFavorites, setAllFavorites } = useGlobalContext();
    const favorites = new Favorites(`${fullMenu?.identifier}`);
    const [expanded, setExpanded] = React.useState<boolean>(
        alwaysExpanded || defaultExpanded
    );
    const { newEvent } = useTrackContext();
    const { restaurant: restaurantIdentifier } = useParams();
    const intl = useIntl();

    function handleToggle(item: { item_id: IItem }) {
        const exists = Boolean(
            allFavorites?.filter((fav: string) => fav === item.item_id._id)
                .length
        );
        if (exists) {
            addFavoriteEvent(item, "item-unfavorite");
            return (
                setAllFavorites &&
                setAllFavorites(favorites.removeFavorite(item.item_id._id))
            );
        }
        addFavoriteEvent(item, "item-favorite");
        setAllFavorites &&
            setAllFavorites(favorites.addFavorite(item.item_id._id));
    }

    function addFavoriteEvent(
        item: { item_id: IItem },
        eventType: "item-favorite" | "item-unfavorite"
    ) {
        newEvent(
            eventType,
            {
                _id: item.item_id._id,
                name: item.item_id.internal_name,
                template: "2"
            },
            restaurantIdentifier || ""
        );
    }

    function itemIsSelected(itemId: string) {
        return allFavorites?.indexOf(itemId) !== -1;
    }

    function launchExpandEvent() {
        newEvent(
            "section-expand",
            {
                _id: id,
                name: internalName,
                template: "2"
            },
            restaurantIdentifier || ""
        );
    }

    function handleExpand(event: React.SyntheticEvent, _expanded: boolean) {
        if (!alwaysExpanded) {
            setExpanded(_expanded);
            _expanded && launchExpandEvent();
        }
    }

    function shouldShowDotNotation(
        item: {
            item_id: IItem;
        },
        i: number
    ): boolean {
        const notations = _.get(item, "item_id.notation_links", []);
        if (!notations || !notations.length) {
            return false;
        }
        return notations.length - 1 !== i;
    }

    const fonts = fullMenu?.presenter_template.fonts;
    const data = fullMenu?.presenter_template;
    return (
        <Box>
            <Accordion
                sx={sx}
                disableGutters
                elevation={0}
                square
                expanded={expanded}
                onChange={handleExpand}
            >
                <AccordionSummary
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    sx={{
                        borderBottomWidth: "thin",
                        position: "relative",
                        width: "auto%",
                        p: "8px 16px 16px",
                        margin: 0,
                        ml: "30px",
                        mr: "25px",
                        "& .MuiAccordionSummary-content": {
                            margin: 0,
                            mb: -3
                        },
                        ":before": {
                            content: `''`,
                            position: "absolute",
                            left: 9,
                            bottom: 0,
                            height: "1px",
                            width: "94%",
                            borderBottom: `1px solid ${fullMenu?.presenter_template.colors?.tertiary}`
                        }
                    }}
                >
                    <Typography
                        sx={{
                            fontFamily: fonts?.section?.family || "Inter",
                            fontSize: fonts?.section?.size,
                            fontWeight: fonts?.section?.weight,
                            fontStyle: fonts?.section?.style,
                            lineHeight: fonts?.section?.line_height,
                            color: fonts?.section?.color
                        }}
                    >
                        {sectionTitle}
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography sx={{ m: 3, mt: 0, mb: 0, p: "6px" }}>
                        {sectionDescription}
                    </Typography>
                    {items.map((item, i: number) =>
                        !item.item_id ? (
                            <React.Fragment key="unknown-item"></React.Fragment>
                        ) : (
                            <Box
                                sx={{
                                    mt: 1
                                }}
                                key={item.item_id?.display_name + i}
                            >
                                <Root
                                    template={data}
                                    style={{
                                        display: "flex"
                                    }}
                                >
                                    <Box sx={{ padding: 0, ml: "30px" }}>
                                        <Typography
                                            sx={{
                                                fontFamily:
                                                    fullMenu?.presenter_template
                                                        .fonts?.item_name
                                                        ?.family,
                                                fontSize:
                                                    fullMenu?.presenter_template
                                                        ?.fonts?.item_name
                                                        ?.size || "1rem",
                                                fontWeight:
                                                    fullMenu?.presenter_template
                                                        ?.fonts?.item_name
                                                        ?.weight || "1.62rem",
                                                fontStyle:
                                                    fullMenu?.presenter_template
                                                        ?.fonts?.item_name
                                                        ?.style,
                                                lineHeight:
                                                    fullMenu?.presenter_template
                                                        ?.fonts?.item_name
                                                        ?.line_height,
                                                color: fullMenu
                                                    ?.presenter_template?.fonts
                                                    ?.item_name?.color
                                            }}
                                        >
                                            {item.item_id?.display_name}
                                        </Typography>
                                        <Typography
                                            sx={{
                                                fontFamily:
                                                    fullMenu?.presenter_template
                                                        .fonts?.item_description
                                                        ?.family,
                                                fontSize:
                                                    fullMenu?.presenter_template
                                                        ?.fonts
                                                        ?.item_description
                                                        ?.size || "1rem",
                                                fontWeight:
                                                    fullMenu?.presenter_template
                                                        ?.fonts
                                                        ?.item_description
                                                        ?.weight || "1.62rem",
                                                fontStyle:
                                                    fullMenu?.presenter_template
                                                        ?.fonts
                                                        ?.item_description
                                                        ?.style,
                                                lineHeight:
                                                    fullMenu?.presenter_template
                                                        ?.fonts
                                                        ?.item_description
                                                        ?.line_height,
                                                color: fullMenu
                                                    ?.presenter_template?.fonts
                                                    ?.item_description?.color
                                            }}
                                        >
                                            {item.item_id?.description}
                                        </Typography>
                                        {item.item_id?.price_variations?.map(
                                            (price, i) => (
                                                <Typography
                                                    className={
                                                        classes.itemPrice
                                                    }
                                                    key={`${price.price}-${i}`}
                                                    sx={{
                                                        fontFamily:
                                                            fullMenu
                                                                ?.presenter_template
                                                                .fonts
                                                                ?.item_price
                                                                ?.family ||
                                                            "Inter",
                                                        fontSize:
                                                            fullMenu
                                                                ?.presenter_template
                                                                ?.fonts
                                                                ?.item_price
                                                                ?.size ||
                                                            "1rem",
                                                        fontWeight:
                                                            fullMenu
                                                                ?.presenter_template
                                                                ?.fonts
                                                                ?.item_price
                                                                ?.weight ||
                                                            "1.62rem",
                                                        fontStyle:
                                                            fullMenu
                                                                ?.presenter_template
                                                                ?.fonts
                                                                ?.item_price
                                                                ?.style,
                                                        lineHeight:
                                                            fullMenu
                                                                ?.presenter_template
                                                                ?.fonts
                                                                ?.item_price
                                                                ?.line_height,
                                                        color: fullMenu
                                                            ?.presenter_template
                                                            ?.fonts?.item_price
                                                            ?.color
                                                    }}
                                                >
                                                    {showPrice(price, {
                                                        currency_symbol:
                                                            fullMenu?.currency_symbol,
                                                        currency_placement:
                                                            fullMenu?.currency_placement,
                                                        show_currency_symbol:
                                                            fullMenu
                                                                ?.presenter_template
                                                                ?.controls
                                                                ?.show_currency_symbol,
                                                        show_empty_decimals:
                                                            fullMenu
                                                                ?.presenter_template
                                                                ?.controls
                                                                ?.show_empty_decimals,
                                                        show_item_price:
                                                            fullMenu
                                                                ?.presenter_template
                                                                ?.controls
                                                                ?.show_item_price
                                                    })}
                                                </Typography>
                                            )
                                        )}
                                        <div
                                            className={
                                                classes.notationConatiner
                                            }
                                        >
                                            {showNotations &&
                                                item.item_id?.notation_links?.map(
                                                    (notation, i) => (
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                justifyContent:
                                                                    "flex-start",
                                                                alignItems:
                                                                    "center",
                                                                marginTop:
                                                                    "1rem"
                                                            }}
                                                            key={
                                                                notation
                                                                    .notation_id
                                                                    ?._id
                                                            }
                                                        >
                                                            <span
                                                                className={
                                                                    classes.notation
                                                                }
                                                            >
                                                                {
                                                                    notation
                                                                        .notation_id
                                                                        ?.display_name
                                                                }
                                                            </span>
                                                            {shouldShowDotNotation(
                                                                item,
                                                                i
                                                            ) && (
                                                                <span
                                                                    className={
                                                                        classes.notationSeparator
                                                                    }
                                                                ></span>
                                                            )}
                                                        </div>
                                                    )
                                                )}
                                        </div>
                                    </Box>
                                    <Box sx={{ flex: "20%", mr: "25px" }}>
                                        {item.item_id?.type !== "title" && (
                                            <Tooltip
                                                title={
                                                    intl.messages[
                                                        "add_to_favorites"
                                                    ] as string
                                                }
                                                onClick={() =>
                                                    handleToggle(item)
                                                }
                                                style={{
                                                    background: "transparent",
                                                    boxShadow: "none",
                                                    color: "white",
                                                    float: "right"
                                                }}
                                            >
                                                <Fab
                                                    size="small"
                                                    sx={{
                                                        zIndex: 0,
                                                        width: "21px",
                                                        height: 0
                                                    }}
                                                >
                                                    {itemIsSelected(
                                                        item.item_id?._id
                                                    ) ? (
                                                        <BookmarkOutlinedIcon
                                                            sx={{
                                                                color: fullMenu
                                                                    ?.presenter_template
                                                                    ?.fonts
                                                                    ?.item_name
                                                                    ?.color
                                                            }}
                                                        ></BookmarkOutlinedIcon>
                                                    ) : (
                                                        <BookmarkBorderOutlinedIcon
                                                            sx={{
                                                                color: fullMenu
                                                                    ?.presenter_template
                                                                    ?.fonts
                                                                    ?.item_name
                                                                    ?.color
                                                            }}
                                                        ></BookmarkBorderOutlinedIcon>
                                                    )}
                                                </Fab>
                                            </Tooltip>
                                        )}
                                    </Box>
                                </Root>
                            </Box>
                        )
                    )}
                </AccordionDetails>
            </Accordion>
        </Box>
    );
}
