/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Container, Grid } from "@mui/material";
import React, { useState } from "react";
import { useGlobalContext } from "../../../context/Global";
import LayoutOne from "../Components/Layout";
import { filterItems } from "../../../utils/filter";
import { IItem } from "../../../const";
import { useTrackContext } from "../../../context/Tracking";
import { useNavigate } from "react-router-dom";
import { Root } from "./styles";
import { Box } from "@mui/system";
import EmptyCart from "../Components/EmptyCart";
import CartSection from "./CartSection";
import { getMobileOS } from "../../../utils/browser";
import { Section as LocalSection } from "../../../utils/sections";
import { Collections } from "../../../utils/collection";
import { CartItem } from "../../../utils/cartItems";
import { useIntl } from "react-intl";

export default function Cart1(props: any) {
    const {
        fullMenu,
        collection,
        search,
        showNotations,
        cartData,
        setCartData
    } = useGlobalContext();
    const [filteredItems, setFiteredItems] = React.useState<
        Array<{
            item_id: IItem;
        }>
    >([]);
    const { newEvent } = useTrackContext();
    const navigate = useNavigate();
    const [modifyPopupPos, setModifyPopupPos] = useState<boolean>(false);

    const sections = new LocalSection(`${fullMenu?.identifier}`);

    const intl = useIntl();

    React.useEffect(() => {
        if (search?.length) {
            //If there is an active search, look for items in the entire collection
            let items: Array<{
                item_id: IItem;
            }> = [];

            // Filter over collections - menus - sections
            collection?.collection_id?.menu_links.forEach((_menu) => {
                _menu?.menu_id?.section_links?.forEach((section) => {
                    items = [
                        ...items,
                        ...filterItems(
                            section.section_id.item_links,
                            search || ""
                        )
                    ];
                });
            });

            return setFiteredItems(getUniqueItems(items));
        }
        // If there is not a search, just return the favorite ones
        setFiteredItems(getUniqueItems(getCartItems()));
    }, [search]);

    // This effect takes care of the background color of the body
    const bodyWrapper = document.querySelector(".body-wrapper") as HTMLElement;
    React.useEffect(() => {
        const backgroundColor =
            fullMenu?.presenter_template.colors?.background_1 || "#fff";
        const className = "bg-" + backgroundColor;
        //  add class to body element
        document.body.classList.add(className);

        //  set style on body element
        bodyWrapper.style.backgroundColor = backgroundColor;

        return () => {
            //  optionally remove styles when component unmounts
            bodyWrapper.style.backgroundColor = "";

            document.body.classList.remove(className);
        };
    }, []);

    React.useEffect(() => {
        newEvent(
            "bookmark-view",
            {
                template: "1",
                bookmark: getCartItems().length
            },
            fullMenu?.identifier || ""
        );
    }, []);

    function getUniqueItems(
        items: {
            item_id: IItem;
        }[]
    ): Array<{
        item_id: IItem;
    }> {
        return items.filter((v, i, a) => {
            return a.findIndex((t) => t.item_id._id === v.item_id._id) === i;
        });
    }
    function getCartItems(): Array<any> {
        const _favorites: any = [];
        fullMenu?.collection_links.forEach((col) => {
            col.collection_id.menu_links.forEach((men) => {
                men.menu_id.section_links.forEach((sec) => {
                    sec.section_id.item_links.forEach((it) => {
                        cartData?.length > 0 &&
                            cartData?.forEach((item: any) => {
                                if (item._id === it.item_id?._id) {
                                    _favorites.push(it);
                                }
                            });
                    });
                });
            });
        });

        return _favorites;
    }

    function clearfavorites() {
        setCartData && setCartData([]);
        const cartItem = new CartItem(`${fullMenu?.identifier}`);
        const collectionId = new Collections(`${fullMenu?.identifier}`);
        const current_collection = collectionId.getcollection();
        cartItem.clearCartItem();
        setFiteredItems([]);

        const sectionState = sections.getSections();
        Object.keys(sectionState).forEach((id) => {
            sections.setSectionState(id, false);
        });

        newEvent(
            "clear-bookmark",
            {
                template: "1"
            },
            fullMenu?.identifier || ""
        );

        const matchingObject = fullMenu?.collection_links.find(
            (item) => item?.collection_id?._id === current_collection
        );

        const firstMenuId =
            matchingObject?.collection_id.menu_links[0]?.menu_id._id ||
            fullMenu?.collection_links[0]?.collection_id.menu_links[0]?.menu_id
                ._id;

        const currentCollection =
            matchingObject?.collection_id._id ||
            fullMenu?.collection_links[0]?.collection_id._id;

        const navigateUrl = `/${fullMenu?.identifier}/${currentCollection}/${firstMenuId}?order=on`;

        return navigate(navigateUrl);
    }
    const data = fullMenu?.presenter_template;

    const urlSearchParams = new URLSearchParams(window.location.search);
    const isWhatsapp = urlSearchParams.get("app") === "wap";

    const orderMessage = cartData
        ?.map((item: any, index, array) => {
            const trimmedString = item.item_message.replace(/^\s+|\s+$/g, "");
            return `${item.item_length} x ${item.display_name}${
                trimmedString !== "" ? ` (${trimmedString})` : ""
            }${
                index === array.length - 1
                    ? ""
                    : index === array.length - 2
                    ? " and\n"
                    : ",\n"
            }`;
        })
        .join("");
    const phone_number = fullMenu?.ohwaiter_phone_number?.replace(/\s/g, "");
    const deviceType = getMobileOS();

    const messageType = isWhatsapp ? "https://wa.me/" : "sms:";
    const bodyType = isWhatsapp ? "text=" : "body=";
    const separator = isWhatsapp ? "?" : deviceType === "iOS" ? "&" : "?";

    const smsData = `${messageType}${phone_number}${separator}${bodyType}${encodeURIComponent(
        `${intl.formatMessage({
            id: "cart.pre_message_text"
        })}\n${orderMessage}.`
    )}`;

    return (
        <LayoutOne
            title={fullMenu?.display_name}
            subtitle={collection?.collection_id?.display_name}
            showSearch
            showNavbar
            showNotationToggle
            templateConfigs={{}}
            template={fullMenu?.presenter_template || {}}
        >
            <Container
                maxWidth="md"
                style={{ padding: "0 0 0 0", position: "relative" }}
            >
                <Grid container>
                    <Grid item xs={12}>
                        <Box
                            className="section-wrapper"
                            sx={{
                                position: "relative",
                                zIndex: modifyPopupPos ? 2 : 1,
                                paddingBottom:
                                    Boolean(filteredItems.length) && !search
                                        ? "93px"
                                        : 0
                            }}
                        >
                            <CartSection
                                sectionTitle={
                                    search
                                        ? intl.formatMessage({
                                              id: "items.search_result"
                                          })
                                        : intl.formatMessage({
                                              id: "cart.title"
                                          })
                                }
                                sectionDescription=""
                                defaultExpanded
                                alwaysExpanded
                                showNotations={showNotations}
                                items={filteredItems}
                                id="cart-section"
                                internalName="cart-section"
                                template={fullMenu?.presenter_template || {}}
                                setModifyPopupPos={setModifyPopupPos}
                            />
                        </Box>
                        {/* If there are favorites, show the option to remove all */}
                        {Boolean(filteredItems.length) && !search && (
                            <Root template={data} className="btnWrapper">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    className="cartBtn clearCartBtn"
                                    onClick={clearfavorites}
                                >
                                    {intl.formatMessage({
                                        id: "cart.clear"
                                    })}
                                </Button>
                                <a
                                    href={smsData}
                                    rel="noreferrer"
                                    className="cartBtn orderBtn"
                                    target="_blank"
                                    onClick={() => {
                                        setTimeout(() => {
                                            clearfavorites();
                                        }, 1000);
                                    }}
                                >
                                    {intl.formatMessage({
                                        id: "cart.text_order"
                                    })}
                                </a>
                            </Root>
                        )}
                        {/* There are not favorites yet */}
                        {!Boolean(filteredItems.length) && !search && (
                            <EmptyCart isCartPage></EmptyCart>
                        )}
                        {/* There are not results of searching */}
                        {!Boolean(filteredItems.length) && search && (
                            <EmptyCart isCartPage={false}></EmptyCart>
                        )}
                    </Grid>
                </Grid>
            </Container>
        </LayoutOne>
    );
}
