import { ArrowUpward } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import React from "react";

export default function BackToTop(props: {
    backgroundColor: string;
    color: string;
}) {
    const { backgroundColor, color } = props;
    const [visible, setVisible] = React.useState(false);

    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        if (scrolled > 300) {
            setVisible(true);
        } else if (scrolled <= 300) {
            setVisible(false);
        }
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    };

    window.addEventListener("scroll", toggleVisible);

    return (
        <div>
            <Tooltip
                title="Back To Top"
                onClick={scrollToTop}
                style={{
                    background: backgroundColor,
                    color: color,
                    display: visible ? "inline" : "none",
                    position: "fixed",
                    right: "30px",
                    bottom: "30px",
                    width: "50px",
                    height: "50px",
                    zIndex: 2000
                }}
            >
                <IconButton>
                    <ArrowUpward></ArrowUpward>
                </IconButton>
            </Tooltip>
        </div>
    );
}
